import Cookies from "js-cookie";
import React, { Component, useEffect, useState } from "react";
import {
  API_UPDATE_EMPLOYEE,
  EMPLOYEE_LIST,
  GET_DEPARTMENT_LIST,
  GET_DESIGNATION_LIST,
  GET_ONBOARDING_EMPLOYEE_LIST,
  SIGN_UP,
} from "../../config/Endpoints";
import { getApi, postApi, putApi } from "../../utils/api";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import Select, { components } from "react-select";
import * as Yup from "yup";
import { isCardNumberValid } from "../../utils/helpers";
import UpdateOnboardingView from "../EmployeeOnboard/UpdateOnboardingView";

const AssignManager = ({
  selectedEmployee,
  fetchList,
  isEditable,
  titleHeading,
  setSelectedEmployee,
}) => {
  const [managerList, setManagerList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [managerId, setManagerId] = useState("");
  const [manager, setManager] = useState("");
  const [leaveManager, setLeaveManager] = useState("");

  const [departmentId, setDepartmentId] = useState("");
  const [designationId, setDesignationId] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [subEmployeeType, setSubEmployeeType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");

  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    bloodGroup: "",
    dob: "",
    aadharNumber: "",
    panCardNumber: "",
    passportNumber: "",
    gender: "",
    joiningDate: "",
    maritalStatus: "",
    anniversaryDate: "",
    phoneNumber: "",
    alternateNumber: "",
    presentAddress: "",
    permanentAddress: "",
    personalEmail: "",
    alternateName: "",
    relationWithAlternateNo: "",
  });
  const [error, setError] = useState("");
  const [errors, setErrors] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    fetchManagerList();
    fetchDepartmentList();
  }, []);

  const fetchManagerList = () => {
    getApi(EMPLOYEE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setManagerList(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDepartmentList = () => {
    getApi(GET_DEPARTMENT_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setDepartmentList(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    setFormData({
      firstName: selectedEmployee?.firstName,
      middleName: selectedEmployee?.middleName,
      lastName: selectedEmployee?.lastName,
      bloodGroup: selectedEmployee?.bloodGroup,
      dob: selectedEmployee?.dob,
      aadharNumber: selectedEmployee?.aadharNum,
      panCardNumber: selectedEmployee?.panNum,
      passportNumber: selectedEmployee?.passportNum,
      gender: selectedEmployee?.gender,
      joiningDate: selectedEmployee?.doj,
      maritalStatus: selectedEmployee?.maritalStatus,
      anniversaryDate: selectedEmployee?.anniversaryDate,
      phoneNumber: selectedEmployee?.phoneNumber,
      alternateNumber: selectedEmployee?.alternateNumber,
      presentAddress: selectedEmployee?.presentAddress,
      permanentAddress: selectedEmployee?.permanentAddress,
      personalEmail: selectedEmployee?.personalEmail,
      alternateName: selectedEmployee?.alternateName,
      relationWithAlternateNo: selectedEmployee?.relationWithAlternateNo,
    });
  }, [selectedEmployee]);

  const handleDepartment = (e) => {
    setDepartmentId(e);
    setDesignationList([]);
    getApi(GET_DESIGNATION_LIST + `?departmentId=${e.value}`, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setDesignationList(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      if (managerId !== "") {
        await putApi(
          GET_ONBOARDING_EMPLOYEE_LIST +
            "/v2/" +
            selectedEmployee.id +
            `/approve?employeeType=${employeeType?.value}&managerId=${managerId}&designationId=${designationId?.value}&leaveManagerId=${leaveManager?.value}&employeeSubType=${subEmployeeType?.value}`,
          {},
          accessToken
        )
          .then((res) => {
            if (res.status === 200) {
              setIsLoading(false);
              const modalClose = document.getElementsByClassName("cancel")[0];
              modalClose.click();
              fetchList();
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                // closeOnClick: true,
                // autoClose: false,
              });
            }
          })
          .catch((e) => {
            setIsLoading(false);

            console.log(e);
          });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const optionList = managerList?.map((item) => ({
    value: item.id,
    label: item.empName,
    type: item.employeeType,
  }));

  const designationListOption = designationList?.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const departmentListOption = departmentList?.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const employeeTypeOption = [
    { value: "REGULAR", label: "REGULAR" },
    { value: "CONTRACT", label: "CONSULTANT" },
  ];
  const employeeSubTypeOption = [
    { value: "Fulltime", label: "Full time" },
    { value: "Intern", label: "Intern" },
  ];

  const Option = (props) => {
    const { data, isSelected } = props;
    const { value, label, username, type } = data;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{label}</span>
          <span style={{ fontSize: "12px", color: "gray" }}>
            {username} ({type})
          </span>
        </div>
      </components.Option>
    );
  };

  const handleManager = (e) => {
    setManager(e);
    setManagerId(e.value);
  };

  const handleReset = () => {
    setDepartmentId("");
    setDesignationId("");
    setManager("");
    setManagerId("");
    setEmployeeType("");
    setDesignationList([]);
    setFormData({
      firstName: "",
      middleName: "",
      lastName: "",
      bloodGroup: "",
      dob: "",
      aadharNumber: "",
      panCardNumber: "",
      passportNumber: "",
      gender: "",
      joiningDate: "",
      maritalStatus: "",
      anniversaryDate: "",
      phoneNumber: "",
      alternateNumber: "",
      presentAddress: "",
      permanentAddress: "",
      personalEmail: "",
      alternateName: "",
      relationWithAlternateNo: "",
    });
    setIsFormContactDisabled(true);
    setIsFormPersonalDisabled(true);
    setSelectedEmployee({});
    // Get all tab buttons and tab content divs
    const tabButtons = document.querySelectorAll(".nav-link");
    const tabContents = document.querySelectorAll(".tab-pane");

    // Add 'active' class to the first tab button and corresponding tab content
    tabButtons[0].classList.add("active");
    tabButtons[0].setAttribute("aria-selected", "true");
    tabContents[0].classList.add("active");
    tabContents[0].classList.add("show");

    // Remove 'active' class from other tab buttons and corresponding tab content
    for (let i = 1; i < tabButtons.length; i++) {
      tabButtons[i].classList.remove("active");
      tabButtons[i].setAttribute("aria-selected", "false");
      tabContents[i].classList.remove("active");
      tabContents[i].classList.remove("show");
    }
  };

  const handleSubmitPersonal = async (event, type) => {
    event.preventDefault();

    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailRegex.test(formData.personalEmail)) {
    //   return toast.error("Invalid email address, please use valid email !", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
    setErrors("");
    setIsLoading(true);
    try {
      if (type === "Personal") {
        await schema.validate(
          {
            personalEmail: formData.personalEmail,
            dob: formData.dob,
            joiningDate: formData.joiningDate,
          },
          { abortEarly: false }
        );
        if (
          !isCardNumberValid("AADHAAR", formData.aadharNumber) ||
          !isCardNumberValid("PAN", formData.panCardNumber)
        ) {
          let newError = {};
          if (
            !formData.aadharNumber ||
            !isCardNumberValid("AADHAAR", formData.aadharNumber)
          ) {
            newError.aadharNumber = "Invalid Aadhaar card number";
          }
          if (
            !formData.panCardNumber ||
            !isCardNumberValid("PAN", formData.panCardNumber)
          ) {
            newError.panCardNumber = "Invalid PAN card number";
          }
          setErrors(newError);
          setIsLoading(false);
        } else {
          let requestData = {
            aadharNum: formData.aadharNumber,
            alternateNumber: formData.alternateNumber,
            anniversaryDate: formData.anniversaryDate,
            bloodGroup: formData.bloodGroup,
            dob: formData.dob,
            doj: formData.joiningDate,
            firstName: formData.firstName,
            gender: formData.gender,
            lastName: formData.lastName,
            maritalStatus: formData.maritalStatus,
            middleName: formData.middleName,
            panNum: formData.panCardNumber,
            passportNum: formData.passportNumber,
            permanentAddress: formData.permanentAddress,
            personalEmail: formData.personalEmail,
            phoneNumber: formData.phoneNumber,
            presentAddress: formData.presentAddress,
            alternateName: formData?.alternateName,
            relationWithAlternateNo: formData?.relationWithAlternateNo,
            id: selectedEmployee?.id,
          };

          await postApi(API_UPDATE_EMPLOYEE, requestData, accessToken)
            .then((res) => {
              if (res.status === 200) {
                toast.success(
                  res.data.message
                    ? res.data.message
                    : "Employee updated successfully !",
                  {
                    position: toast.POSITION.TOP_RIGHT,
                  }
                );
                fetchList();
                if (type === "Personal") {
                  if (!isFormPersonalDisabled) {
                    setIsFormPersonalDisabled(true);
                  }
                } else {
                  if (!isFormContactDisabled) {
                    setIsFormContactDisabled(true);
                  }
                }
                setIsLoading(false);
              } else {
                setIsError(true);
                setIsLoading(false);
                setError("Something went wrong. Please try again!");
                setTimeout(() => {
                  setIsError(false);
                }, 4000);
              }
            })
            .catch((e) => {
              setIsLoading(false);
              toast.error(e.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
        }
      } else {
        let requestData = {
          aadharNum: formData.aadharNumber,
          alternateNumber: formData.alternateNumber,
          anniversaryDate: formData.anniversaryDate,
          bloodGroup: formData.bloodGroup,
          dob: formData.dob,
          doj: formData.joiningDate,
          firstName: formData.firstName,
          gender: formData.gender,
          lastName: formData.lastName,
          maritalStatus: formData.maritalStatus,
          middleName: formData.middleName,
          panNum: formData.panCardNumber,
          passportNum: formData.passportNumber,
          permanentAddress: formData.permanentAddress,
          personalEmail: formData.personalEmail,
          phoneNumber: formData.phoneNumber,
          presentAddress: formData.presentAddress,
          alternateName: formData?.alternateName,
          relationWithAlternateNo: formData?.relationWithAlternateNo,
          id: selectedEmployee?.id,
        };

        await postApi(API_UPDATE_EMPLOYEE, requestData, accessToken)
          .then((res) => {
            if (res.status === 200) {
              toast.success(
                res.data.message
                  ? res.data.message
                  : "Employee updated successfully !",
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              fetchList();
              if (type === "Personal") {
                if (!isFormPersonalDisabled) {
                  setIsFormPersonalDisabled(true);
                }
              } else {
                if (!isFormContactDisabled) {
                  setIsFormContactDisabled(true);
                }
              }
              setIsLoading(false);
            } else {
              setIsError(true);
              setIsLoading(false);
              setError("Something went wrong. Please try again!");
              setTimeout(() => {
                setIsError(false);
              }, 4000);
            }
          })
          .catch((e) => {
            setIsLoading(false);
            toast.error(e.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    setErrors("");
    if (type === "date") {
      const year = value.split("-")[0];
      if (year.length <= 4) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  // Get today's date in the format yyyy-mm-dd
  const today = new Date().toISOString().split("T")[0];

  // Function to get the minimum selectable date (today's date - 30 days)
  function getMinDate() {
    const today = new Date();
    const minDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 30
    );
    return minDate.toISOString().split("T")[0];
  }

  const [isFormPersonalDisabled, setIsFormPersonalDisabled] = useState(true);
  const [isFormContactDisabled, setIsFormContactDisabled] = useState(true);

  const handleEnablePersonalForm = () => {
    setIsFormPersonalDisabled(!isFormPersonalDisabled);
  };
  const handleEnableContactForm = () => {
    setIsFormContactDisabled(!isFormContactDisabled);
  };

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title text-center flex" id="staticBackdropLabel">
          {titleHeading}
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleReset}
        ></button>
      </div>
      <div className="modal-body">
        <ul className="nav nav-tabs" id="myTabs" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="tab1-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab1"
              type="button"
              role="tab"
              aria-controls="tab1"
              aria-selected="true"
            >
              Personal Detail
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="tab2-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab2"
              type="button"
              role="tab"
              aria-controls="tab2"
              aria-selected="false"
            >
              Contact Detail
            </button>
          </li>
          {(isEditable || titleHeading === "L1 Approval Action") && (
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="tab3-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab3"
                type="button"
                role="tab"
                aria-controls="tab3"
                aria-selected="false"
              >
                Approve
              </button>
            </li>
          )}
          {/* <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="tab4-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab4"
              type="button"
              role="tab"
              aria-controls="tab4"
              aria-selected="false"
            >
              Asset
            </button>
          </li> */}
        </ul>
        <div className="tab-content" id="myTabsContent">
          <div
            className="tab-pane fade show active mt-3"
            id="tab1"
            role="tabpanel"
            aria-labelledby="tab1-tab"
          >
            <div className="card">
              {isEditable && (
                <div className="m-2 text-end">
                  {!isFormPersonalDisabled ? (
                    <a
                      className="mx-2 text-danger cursor-link"
                      onClick={handleEnablePersonalForm}
                    >
                      Cancel
                    </a>
                  ) : (
                    <a
                      className="ms-1 text-primary cursor-link"
                      onClick={handleEnablePersonalForm}
                    >
                      Edit
                    </a>
                  )}
                </div>
              )}
              <form
                id="createEmployeeForm_pageone"
                onSubmit={(e) => handleSubmitPersonal(e, "Personal")}
                disabled={isFormPersonalDisabled}
              >
                <div className="d-f f-w inputs gap-15">
                  <div className="input-group">
                    <label htmlFor="firstName" className="has-asterisk">
                      First Name
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      placeholder="Enter First Name"
                      required
                      onKeyPress={(e) => {
                        const inputValue = String.fromCharCode(e.charCode);
                        if (
                          (e.charCode === 32 &&
                            e.target.selectionStart === 0) ||
                          (inputValue.match(/^\d+$/) &&
                            e.target.selectionStart === 0)
                        ) {
                          e.preventDefault(); // Prevent adding space at the beginning  of the string or numbers only
                        }
                      }}
                      disabled={isFormPersonalDisabled}
                      maxLength={100}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="middleName">Middle Name</label>
                    <input
                      type="text"
                      name="middleName"
                      value={formData.middleName}
                      onChange={handleInputChange}
                      placeholder="Enter Middle Name"
                      disabled={isFormPersonalDisabled}
                      onKeyPress={(e) => {
                        const inputValue = String.fromCharCode(e.charCode);
                        if (
                          (e.charCode === 32 &&
                            e.target.selectionStart === 0) ||
                          (inputValue.match(/^\d+$/) &&
                            e.target.selectionStart === 0)
                        ) {
                          e.preventDefault(); // Prevent adding space at the beginning  of the string or numbers only
                        }
                      }}
                      maxLength={100}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="lastName" className="has-asterisk">
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      disabled={isFormPersonalDisabled}
                      onChange={handleInputChange}
                      placeholder="Enter Last Name"
                      required
                      onKeyPress={(e) => {
                        const inputValue = String.fromCharCode(e.charCode);
                        if (
                          (e.charCode === 32 &&
                            e.target.selectionStart === 0) ||
                          (inputValue.match(/^\d+$/) &&
                            e.target.selectionStart === 0)
                        ) {
                          e.preventDefault(); // Prevent adding space at the beginning  of the string or numbers only
                        }
                      }}
                      maxLength={100}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="personalEmail" className="has-asterisk">
                      Personal Email
                    </label>
                    <input
                      type="email"
                      name="personalEmail"
                      maxLength={100}
                      disabled
                      value={formData.personalEmail}
                      onChange={handleInputChange}
                      placeholder="Enter Personal Email"
                      required
                    />
                    {errors.personalEmail && (
                      <small className="text-danger">
                        {errors.personalEmail}
                      </small>
                    )}
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15">
                  <div className="input-group">
                    <label className="has-asterisk">Gender</label>
                    <div className="custom-checkbox small">
                      <div>
                        <input
                          type="radio"
                          id="male"
                          name="gender"
                          value="male"
                          checked={formData.gender === "male"}
                          onChange={handleInputChange}
                          disabled={isFormPersonalDisabled}
                        />
                        <label htmlFor="male">Male</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="female"
                          name="gender"
                          value="female"
                          checked={formData.gender === "female"}
                          onChange={handleInputChange}
                          disabled={isFormPersonalDisabled}
                        />
                        <label htmlFor="female">Female</label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="others"
                          name="gender"
                          value="others"
                          required
                          checked={formData.gender === "others"}
                          onChange={handleInputChange}
                          disabled={isFormPersonalDisabled}
                        />
                        <label htmlFor="others">Others</label>
                      </div>
                    </div>
                  </div>
                  <div className="input-group">
                    <label htmlFor="bloodGroup" className="has-asterisk">
                      Blood Group
                    </label>
                    <select
                      id="bloodGroup"
                      value={formData.bloodGroup}
                      required
                      onChange={handleInputChange}
                      name="bloodGroup"
                      disabled={isFormPersonalDisabled}
                    >
                      <option value="">Select Blood Group</option>
                      <option value="A+ve">A+ve</option>
                      <option value="A-ve">A-ve</option>
                      <option value="B+ve">B+ve</option>
                      <option value="B-ve">B-ve</option>
                      <option value="AB+ve">AB+ve</option>
                      <option value="AB-ve">AB-ve</option>
                      <option value="O+ve">O+ve</option>
                      <option value="O-ve">O-ve</option>
                    </select>
                  </div>
                  <div className="input-group">
                    <label htmlFor="dob" className="has-asterisk">
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      required
                      name="dob"
                      value={formData.dob}
                      placeholder="DD/MM/YYYY"
                      onChange={handleInputChange}
                      max={today}
                      disabled={isFormPersonalDisabled}
                    />
                    {errors.dob && (
                      <small className="text-danger">{errors.dob}</small>
                    )}
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15">
                  <div className="input-group">
                    <label htmlFor="aadharNumber" className="has-asterisk">
                      Aadhar Number
                    </label>
                    <input
                      type="tel"
                      required
                      maxLength="12"
                      minLength="12"
                      name="aadharNumber"
                      value={formData.aadharNumber}
                      // onChange={handleInputChange}
                      placeholder="XXXX-XXXX-XXXX"
                      onChange={(e) => {
                        const input = e.target;
                        if (input.value.length < 12) {
                          input.setCustomValidity(
                            "Please enter a 12-digit Aadhar card number"
                          );
                        } else if (input.value.length > 12) {
                          input.setCustomValidity(
                            "Aadhar card number should not exceed 12 digits"
                          );
                        } else {
                          input.setCustomValidity("");
                        }
                        handleInputChange(e);
                      }}
                      disabled={isFormPersonalDisabled}
                      title="Aadhar Number must be a 12-digit number"
                      onKeyPress={(e) => {
                        const isDigit = /\d/.test(e.key);
                        const isLengthValid = e.target.value.length < 13;

                        if (!isDigit || !isLengthValid) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                    />
                    {errors.aadharNumber && (
                      <small className="text-danger">
                        {errors.aadharNumber}
                      </small>
                    )}
                  </div>
                  <div className="input-group">
                    <label htmlFor="panCardNumber" className="has-asterisk">
                      PAN Card Number
                    </label>
                    <input
                      type="text"
                      required
                      name="panCardNumber"
                      maxLength="10"
                      minLength="10"
                      value={formData.panCardNumber}
                      onChange={(e) => {
                        const input = e.target;
                        if (input.value.length < 10) {
                          input.setCustomValidity(
                            "Please enter a 10-digit PAN card number"
                          );
                        } else if (input.value.length > 10) {
                          input.setCustomValidity(
                            "PAN card number should not exceed 10 digits"
                          );
                        } else {
                          input.setCustomValidity("");
                        }
                        handleInputChange(e);
                      }}
                      disabled={isFormPersonalDisabled}
                      placeholder="XXX-XXX-XXXX"
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onKeyPress={(e) => {
                        if (
                          e.charCode === 32 &&
                          e.target.selectionStart === 0
                        ) {
                          e.preventDefault(); // Prevent adding space at the beginning
                        }
                      }}
                    />
                    {errors.panCardNumber && (
                      <small className="text-danger">
                        {errors.panCardNumber}
                      </small>
                    )}
                  </div>
                  <div className="input-group">
                    <label htmlFor="passportNumber">Passport Number</label>
                    <input
                      type="text"
                      maxLength="12"
                      minLength="12"
                      name="passportNumber"
                      value={formData.passportNumber}
                      onChange={(e) => {
                        const input = e.target;
                        if (input.value.length < 12) {
                          input.setCustomValidity(
                            "Please enter a 12-digit Passport number"
                          );
                        } else if (input.value.length > 12) {
                          input.setCustomValidity(
                            "Passport number should not exceed 12 digits"
                          );
                        } else {
                          input.setCustomValidity("");
                        }
                        handleInputChange(e);
                      }}
                      disabled={isFormPersonalDisabled}
                      placeholder="XXXX-XXXX-XXXX"
                      onKeyPress={(e) => {
                        if (
                          e.charCode === 32 &&
                          e.target.selectionStart === 0
                        ) {
                          e.preventDefault(); // Prevent adding space at the beginning
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="d-f f-w inputs gap-15">
                  <div className="input-group">
                    <label htmlFor="joiningDate" className="has-asterisk">
                      Date of Joining
                    </label>
                    <input
                      type="date"
                      name="joiningDate"
                      value={formData.joiningDate}
                      onChange={handleInputChange}
                      disabled={isFormPersonalDisabled}
                      placeholder="DD/MM/YYYY"
                      min={getMinDate()}
                      // max={today}
                      required
                    />
                    {errors.joiningDate && (
                      <small className="text-danger">
                        {errors.joiningDate}
                      </small>
                    )}
                  </div>
                  <div className="input-group">
                    <label htmlFor="maritalStatus" className="has-asterisk">
                      Marital Status
                    </label>
                    <select
                      id="maritalStatus"
                      value={formData.maritalStatus}
                      required
                      onChange={handleInputChange}
                      disabled={isFormPersonalDisabled}
                      name="maritalStatus"
                    >
                      <option value="">Select Marital Status</option>
                      <option value="Single">Single</option>
                      <option value="Married">Married</option>
                      <option value="Widowed">Widowed</option>
                      <option value="Separated">Separated</option>
                      <option value="Divorced">Divorced</option>
                    </select>
                  </div>
                  {formData.maritalStatus === "Married" && (
                    <div className="input-group">
                      <label htmlFor="" className="has-asterisk">
                        Anniversary Date
                      </label>
                      <input
                        type="date"
                        name="anniversaryDate"
                        value={formData.anniversaryDate}
                        onChange={handleInputChange}
                        placeholder="DD/MM/YYYY"
                        max={today}
                        required
                      />
                    </div>
                  )}
                </div>
                {!isFormPersonalDisabled && (
                  <div className="action d-flex justify-content-end mt-5 ">
                    <button
                      type="submit"
                      className={
                        isLoading ? "theme-button disabled " : "theme-button "
                      }
                    >
                      {isLoading ? (
                        <ThreeDots
                          height="20"
                          width="53"
                          radius="9"
                          color="white"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
          <div
            className="tab-pane fade mt-3"
            id="tab2"
            role="tabpanel"
            aria-labelledby="tab2-tab"
          >
            <div className="card">
              {isEditable && (
                <div className="m-2 text-end">
                  {!isFormContactDisabled ? (
                    <a
                      className="mx-2 text-danger cursor-link"
                      onClick={handleEnableContactForm}
                    >
                      Cancel
                    </a>
                  ) : (
                    <a
                      className="ms-1 text-primary cursor-link"
                      onClick={handleEnableContactForm}
                    >
                      Edit
                    </a>
                  )}
                </div>
              )}
              <form
                id="createEmployeeForm_pagetwo"
                onSubmit={(e) => handleSubmitPersonal(e, "Contact")}
              >
                <div className="d-f f-w inputs gap-15">
                  <div className="input-group">
                    <label htmlFor="phoneNumber" className="has-asterisk">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      required
                      maxLength="10"
                      minLength="10"
                      disabled={isFormContactDisabled}
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleInputChange}
                      placeholder="Enter Phone Number"
                      onKeyPress={(e) => {
                        const regex = /^[0-9\b]+$/;
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="alternateNumber" className="has-asterisk">
                      Alternate Number
                    </label>
                    <input
                      type="tel"
                      name="alternateNumber"
                      minLength="10"
                      maxLength="10"
                      disabled={isFormContactDisabled}
                      value={formData.alternateNumber}
                      onChange={handleInputChange}
                      placeholder="Enter Alternate Number"
                      onKeyPress={(e) => {
                        const regex = /^[0-9\b]+$/;
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="middleName" className="has-asterisk">
                      Relationship with alternate number
                    </label>
                    <select
                      id="relationWithAlternateNo"
                      name="relationWithAlternateNo"
                      value={formData.relationWithAlternateNo}
                      disabled={isFormContactDisabled}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Relation</option>
                      <option value="mother">Mother</option>
                      <option value="father">Father</option>
                      {(formData.maritalStatus === "Married" ||
                        formData.gender === "others") && (
                        <>
                          {(formData.gender === "female" ||
                            formData.gender === "others") && (
                            <option value="husband">Husband</option>
                          )}
                          {(formData.gender === "male" ||
                            formData.gender === "others") && (
                            <option value="wife">Wife</option>
                          )}
                        </>
                      )}
                    </select>
                  </div>
                  <div className="input-group">
                    <label htmlFor="alternateName" className="has-asterisk">
                      Alternate Name
                    </label>
                    <input
                      type="text"
                      name="alternateName"
                      value={formData.alternateName}
                      onChange={handleInputChange}
                      disabled={isFormContactDisabled}
                      placeholder="Enter Alternate Name"
                      onKeyPress={(e) => {
                        const inputValue = String.fromCharCode(e.charCode);
                        if (
                          (e.charCode === 32 &&
                            e.target.selectionStart === 0) ||
                          (inputValue.match(/^\d+$/) &&
                            e.target.selectionStart === 0)
                        ) {
                          e.preventDefault(); // Prevent adding space at the beginning  of the string or numbers only
                        }
                      }}
                      required
                      maxLength={100}
                    />
                  </div>
                </div>
                <div className="d-f f-w inputs gap-15">
                  <div className="input-group">
                    <label htmlFor="presentAddress" className="has-asterisk">
                      Present Address
                    </label>
                    <textarea
                      name="presentAddress"
                      value={formData.presentAddress}
                      disabled={isFormContactDisabled}
                      onChange={handleInputChange}
                      required
                      onKeyPress={(e) => {
                        // Prevent adding space at the beginning
                        if (
                          e.charCode === 32 &&
                          e.target.selectionStart === 0
                        ) {
                          e.preventDefault();
                        }

                        // Regular expression to allow only letters, numbers, and spaces
                        const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                        // Check if the character is allowed
                        const char = String.fromCharCode(e.charCode);
                        if (!allowedCharsRegex.test(char)) {
                          e.preventDefault(); // Prevent non-alphanumeric character input
                        }
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="permanentAddress" className="has-asterisk">
                      Permanent Address
                    </label>
                    <textarea
                      name="permanentAddress"
                      value={formData.permanentAddress}
                      onChange={handleInputChange}
                      disabled={isFormContactDisabled}
                      required
                      onKeyPress={(e) => {
                        // Prevent adding space at the beginning
                        if (
                          e.charCode === 32 &&
                          e.target.selectionStart === 0
                        ) {
                          e.preventDefault();
                        }

                        // Regular expression to allow only letters, numbers, and spaces
                        const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                        // Check if the character is allowed
                        const char = String.fromCharCode(e.charCode);
                        if (!allowedCharsRegex.test(char)) {
                          e.preventDefault(); // Prevent non-alphanumeric character input
                        }
                      }}
                    />
                  </div>
                </div>
                {!isFormContactDisabled && (
                  <div className="action d-flex justify-content-end mt-5 ">
                    <button
                      type="submit"
                      className={
                        isLoading ? "theme-button disabled " : "theme-button "
                      }
                    >
                      {isLoading ? (
                        <ThreeDots
                          height="20"
                          width="53"
                          radius="9"
                          color="white"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="tab3"
            role="tabpanel"
            aria-labelledby="tab3-tab"
          >
            {titleHeading !== "L1 Approval Action" && (
              <form onSubmit={handleSubmit}>
                <div className="my-4">
                  <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                      <label>Employee Name</label>
                    </div>

                    <div className="col-md-4">
                      <h6 className="truncate-text">
                        {formData?.firstName + " " + formData?.lastName}
                      </h6>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                      <label> Caelius Email</label>
                    </div>

                    <div className="col-md-4">
                      <h6 className="truncate-text">
                        {selectedEmployee?.ccEmail}
                      </h6>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                      <label htmlFor="department" className="has-asterisk">
                        Department
                      </label>
                    </div>
                    <div className="col-md-4">
                      <Select
                        options={departmentListOption}
                        onChange={handleDepartment}
                        value={departmentId}
                        placeholder="Select Department"
                        isSearchable={true}
                        isDisabled={departmentList?.length === 0}
                        isRequired={true}
                        required
                      />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                      <label htmlFor="designation" className="has-asterisk">
                        Designation
                      </label>
                    </div>
                    <div className="col-md-4">
                      <Select
                        options={designationListOption}
                        onChange={(e) => setDesignationId(e)}
                        value={designationId}
                        placeholder="Select Designation"
                        isSearchable={true}
                        isDisabled={designationList?.length === 0}
                        isRequired={true}
                        required
                      />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                      <label htmlFor="manager" className="has-asterisk">
                        Assign Manager
                      </label>
                    </div>
                    <div className="col-md-4">
                      <div className="dropdown-container">
                        <Select
                          options={optionList}
                          components={{ Option }}
                          onChange={handleManager}
                          value={manager}
                          placeholder="Select Manager"
                          isSearchable={true}
                          isDisabled={managerList?.length === 0}
                          isRequired={true}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                      <label htmlFor="manager" className="has-asterisk">
                        Leave Manager
                      </label>
                    </div>
                    <div className="col-md-4">
                      <div className="dropdown-container">
                        <Select
                          options={optionList}
                          components={{ Option }}
                          onChange={(e) => setLeaveManager(e)}
                          value={leaveManager}
                          placeholder="Select Leave Manager"
                          isSearchable={true}
                          isDisabled={managerList?.length === 0}
                          isRequired={true}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                      <label htmlFor="employeeType" className="has-asterisk">
                        Employee Type
                      </label>
                    </div>
                    <div className="col-md-4">
                      <Select
                        options={employeeTypeOption}
                        onChange={(e) => setEmployeeType(e)}
                        value={employeeType}
                        placeholder="Select Employee Type"
                        isSearchable={true}
                        isRequired={true}
                        required
                      />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                      <label htmlFor="employeeType" className="has-asterisk">
                        Employee Sub Type
                      </label>
                    </div>
                    <div className="col-md-4">
                      <Select
                        options={employeeSubTypeOption}
                        onChange={(e) => setSubEmployeeType(e)}
                        value={subEmployeeType}
                        placeholder="Select Sub Employee Type"
                        isSearchable={true}
                        isRequired={true}
                        required
                      />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                </div>
                <div className="justify-content-center  modal-footer">
                  <button
                    type="submit"
                    className={
                      isLoading ? "theme-button disabled " : "theme-button "
                    }
                  >
                    {isLoading ? (
                      <ThreeDots
                        height="20"
                        width="53"
                        radius="9"
                        color="white"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      "Approve"
                    )}
                  </button>
                  <button
                    type="button"
                    className="theme-button bg-grey mx-3 w-35 cancel"
                    data-bs-dismiss="modal"
                    onClick={handleReset}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            )}
            {titleHeading === "L1 Approval Action" && (
              <UpdateOnboardingView
                selectedEmployee={selectedEmployee}
                fetchApprovedUser={fetchList}
                handleReset={handleReset}
              />
            )}
          </div>
          {/* 
          <div
            className="tab-pane fade"
            id="tab4"
            role="tabpanel"
            aria-labelledby="tab4-tab"
          ></div> */}
        </div>
      </div>
    </>
  );
};

export default AssignManager;

const schema = Yup.object().shape({
  personalEmail: Yup.string()
    .email("Invalid email address")
    .required("Personal Email is required")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address"),

  dob: Yup.date()
    .test(
      "is-18-or-over",
      "You must be at least 18 years old.",
      function (value) {
        const today = new Date();
        const age = today.getFullYear() - value.getFullYear();
        return age >= 18;
      }
    )
    .required(),

  joiningDate: Yup.date()
    .required("Date of Joining is required")
    .min(
      new Date(new Date().setDate(new Date().getDate() - 30)),
      "Date of Joining should be within the past 30 days"
    ),
  // .max(
  //   new Date(new Date().setDate(new Date().getDate() + 30)),
  //   "Date of Joining cannot be more than 30 days in the future"
  // ),
});
