import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { getApi } from "../../utils/api";
import {
  API_DOWNLOAD_SALARY,
  API_REIMBURSEMENT_LIST,
} from "../../config/Endpoints";
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { toggleMenu } from "../../utils/helpers";
import Pagination from "../../components/Pagination";
import ReimbursementWithdrawRequest from "./ReimbursementWithdrawRequest";
import Footer from "../../components/Footer";

// Data table
// import DataTable from "react-data-table-component";

const ReimbursementRequestList = () => {
  const [reimbursementList, setReimbursementList] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isReimbursementRequest, setIsReimbursementRequest] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "",
  });
  const [reimbursementId, setReimbursementId] = useState("");
  const [selectedReimbursementRequest, setSelectedReimbursementRequest] =
    useState({});

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalReimbursement, setTotalReimbursement] = useState(0);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, limit]);

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  const fetchList = () => {
    setIsLoading(true);
    getApi(
      API_REIMBURSEMENT_LIST + `?pageSize=${limit}&page=${currentPage}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          setReimbursementList(res.data.data);
          setFilteredItems(res.data.data);
          setTotalReimbursement(res.data.totalRequest);
          setTotalPages(res.data.totalPages);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = reimbursementList.filter((item) =>
      item.reimbursementType.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredItems].sort((a, b) => {
      if (key === "requestDate" || key === "date") {
        const dateA = new Date(a[key]);
        const dateB = new Date(b[key]);
        if (dateA < dateB) {
          return direction === "ascending" ? -1 : 1;
        }
        if (dateA > dateB) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      } else {
        if (a[key] < b[key]) {
          return direction === "ascending" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      }
    });

    setFilteredItems(sortedData);
  };

  // const data = reimbursementList;

  // const columns = [
  //   {
  //     name: "Reimbursement ID",
  //     selector: "reimbursementViewId",
  //     sortable: true,
  //   },
  //   {
  //     name: "Reimbursement Type",
  //     selector: "reimbursementType",
  //     sortable: true,
  //   },
  //   { name: "Requested Date", selector: "requestDate", sortable: true },
  //   {
  //     name: "Attachment",
  //     selector: "filePath",
  //     sortable: false,
  //     cell: (row) => (
  //       <a
  //         href={process.env.REACT_APP_IMAGE_URL + row.filePath}
  //         download
  //         target="_blank"
  //       >
  //         View
  //       </a>
  //     ),
  //   },
  //   { name: "Status ", selector: "trackingStatus", sortable: true },
  //   {
  //     name: "Actions",
  //     cell: (row) => (
  //       <button onClick={() => handleButtonClick(row)}>Click me</button>
  //     ),
  //   },
  // ];
  // const handleButtonClick = (row) => {
  //   // Handle button click for the specific row
  //   console.log(`Button clicked for row with ID ${row.id}`);
  // };

  // imp Reimbursement TimeSlot code
  // const isReimbursementTimeSlot = () => {
  //   const currentDate = new Date();
  //   const currentDay = currentDate.getDate();
  //   return currentDay <= 12;
  // };
  // const handleReimbursementClick = () => {
  //   if (!isReimbursementTimeSlot()) {
  //     setIsReimbursementRequest(true);
  //     setTimeout(() => {
  //       setIsReimbursementRequest(false);
  //     }, 7000);
  //   }
  // };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>Reimbursement</h1>
        <div className="total">
          Total Reimbursement : <span>{totalReimbursement}</span>
        </div>
      </div>

      {/* Filters */}
      <div className="filters">
        {/* <form action="index.html" id="form_filters" className="d-f"> */}
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Reimbursement Type"
            value={searchTerm}
            onChange={handleSearch}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>

        {/* <button type="submit" form="form_filters">
          Search
        </button> */}
        {/* </form> */}

        {/* Actions */}
        <div className="actions">
          <Link
            to="/dashboard/createReimbursementRequest"
            className="export theme-button"
          >
            Reimbursement Request
          </Link>
          {/* {isReimbursementTimeSlot() ? (
            <Link
              to="/dashboard/createReimbursementRequest"
              className="export theme-button"
            >
              Reimbursement Request
            </Link>
          ) : (
            <button className="theme-button" onClick={handleReimbursementClick}>
              Reimbursement Request
            </button>
          )} */}
        </div>
      </div>

      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      {/* {isReimbursementRequest && (
        <div class="alert alert-danger fw-bold text-center" role="alert">
          Sorry, reimbursement requests can only be submitted between the 1st
          and 12th of each month. Please wait for the next cycle to submit your
          request.
          <br />
          Thank you for your understanding.
        </div>
      )} */}

      {/* Data Table */}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th onClick={() => handleSort("reimbursementViewId")}>
                    Serial No.
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("reimbursementType")}>
                    Reimbursement Type{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("requestDate")}>
                    Requested Date{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("date")}>
                    Invoice Date{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("invoiceNo")}>
                    Invoice No.{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("reimbursementAmount")}>
                    Amount{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  {/* <th>Attachment</th> */}
                  <th onClick={() => handleSort("trackingStatus")}>
                    Status{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th>Action</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems &&
                  filteredItems?.map((item, index) => (
                    <tr key={index}>
                      <td data-title="reimbursementViewId">
                        {item.reimbursementViewId}
                      </td>
                      <td data-title="name">{item.reimbursementType}</td>
                      <td data-title="requestDate">{item.requestDate}</td>
                      <td data-title="date">{item.date}</td>
                      <td data-title="invoiceNo">{item.invoiceNo}</td>
                      {/* <td>
                        <a
                          href={process.env.REACT_APP_IMAGE_URL + item.filePath}
                          download
                          target="_blank"
                        >
                          View
                        </a>
                      </td> */}
                      {/* <td data-title="reimbursementAmount">
                        {item.reimbursementAmount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </td> */}
                      <td
                        data-title="reimbursementAmount"
                        className="text-right"
                      >
                        {item.reimbursementAmount.toLocaleString("en-IN")}
                      </td>

                      <td data-title="trackingStatus">{item.trackingStatus}</td>
                      <td data-title="action">
                        {item.trackingStatus === "Discrepancy" ||
                        item.trackingStatus === "Pending-Admin" ? (
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdropAssetwithdraw"
                            onClick={() => {
                              setReimbursementId(item.reimbursementId);
                              setSelectedReimbursementRequest(item);
                            }}
                          >
                            Withdraw
                          </a>
                        ) : (
                          <p className="ms-2">-</p>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div>
                {/* Dropdown for selecting items per page */}
                <label htmlFor="itemsPerPage">Items per page </label>
                <select
                  id="itemsPerPage"
                  onChange={handleLimitChange}
                  value={limit}
                  className="border ms-3 w-25  p-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                </select>
              </div>
            </div>
            <div className="col-md-9">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}

      <div
        className="modal fade"
        id="staticBackdropAssetwithdraw"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <ReimbursementWithdrawRequest
              reimbursementId={reimbursementId}
              selectedReimbursementRequest={selectedReimbursementRequest}
              fetchList={fetchList}
            />
          </div>
        </div>
      </div>
      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center">
          <h4>No Record Available</h4>
        </div>
      )}
      {/* <DataTable columns={columns} data={data} pagination /> */}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ReimbursementRequestList;
