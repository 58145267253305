import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { getApi, postApi, putApi } from "../../utils/api";
import {
  API_APPROVE_REQUEST,
  API_V2_APPROVE_REQUEST,
  ASSET_LIST,
} from "../../config/Endpoints";
import { toast } from "react-toastify";
import parse from "html-react-parser";

const AssetRequestStoreApprove = ({
  assetId,
  selectedAssetRequest,
  assetStatusList,
  fetchData,
  assetAvailability,
  header,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [formData, setFormData] = useState({
    action: "",
    comment: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await putApi(
        API_V2_APPROVE_REQUEST +
          "?assestRequestId=" +
          assetId +
          "&comment=" +
          formData.comment +
          "&status=" +
          formData.action,
        {},
        accessToken
      )
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            const modalClose =
              document.getElementsByClassName("cancel-modal")[0];
            modalClose.click();
            fetchData();
          } else {
            setIsLoading(false);
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            const modalClose =
              document.getElementsByClassName("cancel-modal")[0];
            modalClose.click();
          }
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error("Something went wrong please try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFormData({
      action: "",
      comment: "",
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      {isLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="modal-header">
        <h5 className="modal-title text-center flex" id="staticBackdropLabel">
          {header}
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleReset}
        ></button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="resume custom">
                {/* Table Headings */}
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>Asset Type</th>
                    <th>Requested Date</th>
                    <th>Reason</th>
                    <th>Allocated Asset</th>
                    <th>Manufacture</th>
                    <th>Serial Number</th>
                    <th>Owner</th>
                  </tr>
                </thead>
                {/* Table Body */}
                <tbody>
                  <tr>
                    <td data-title="employeeName">
                      {selectedAssetRequest?.employeeName}
                    </td>
                    <td data-title="name">
                      {selectedAssetRequest?.assestType}
                    </td>
                    <td data-title="requestDate">
                      {selectedAssetRequest?.requestDate}
                    </td>
                    <td data-title="reason">{selectedAssetRequest?.reason}</td>
                    <td data-title="name">
                      {selectedAssetRequest?.allocatedAsset?.assestType.name}
                    </td>
                    <td data-title="manufacture">
                      {selectedAssetRequest?.allocatedAsset?.manufacture}
                    </td>
                    <td data-title="serialNumber">
                      {selectedAssetRequest?.allocatedAsset?.serialNumber}
                    </td>
                    <td data-title="owner">
                      {selectedAssetRequest?.allocatedAsset?.owner}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="action" className="has-asterisk">
              Action
            </label>
          </div>

          <div className="col-md-4">
            <select
              id="action"
              required
              name="action"
              className="border"
              value={formData.action}
              onChange={handleInputChange}
            >
              <option value="">Select Action</option>
              {assetStatusList?.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="comment" className="has-asterisk">
              Comment
            </label>
          </div>
          <div className="col-md-4">
            <textarea
              rows={3}
              cols={5}
              className="border"
              name="comment"
              value={formData.comment}
              onChange={handleInputChange}
              required
              onKeyPress={(e) => {
                // Prevent adding space at the beginning
                if (e.charCode === 32 && e.target.selectionStart === 0) {
                  e.preventDefault();
                }

                // Regular expression to allow only letters, numbers, and spaces
                const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                // Check if the character is allowed
                const char = String.fromCharCode(e.charCode);
                if (!allowedCharsRegex.test(char)) {
                  e.preventDefault(); // Prevent non-alphanumeric character input
                }
              }}
            ></textarea>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>

      <div className="justify-content-center modal-footer">
        <button
          type="submit"
          className={isLoading ? "theme-button disabled " : "theme-button "}
        >
          {isLoading ? (
            <ThreeDots
              height="20"
              width="53"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            "Submit"
          )}
        </button>
        <button
          type="button"
          className="theme-button bg-grey mx-3 w-35 cancel-modal"
          data-bs-dismiss="modal"
          onClick={handleReset}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default AssetRequestStoreApprove;
