import { useEffect, useState } from "react";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import {
  API_PERFORMANCE_Evaluation,
  API_PERFORMANCE_POINTS,
  GET_EMPLOYEE_LIST,
} from "../../config/Endpoints";
import Cookies from "js-cookie";
import { getApi, postApi } from "../../utils/api";
import { toggleMenu } from "../../utils/helpers";
import Select, { components } from "react-select";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";

const EvaluateEmployee = () => {
  const [employeeList, setEmployeeList] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEmployeeSelect, setSelectedEmployeeSelect] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [evaluatePoint, setEvaluatePoint] = useState({});

  const accessToken = Cookies.get("accessToken");
  const [formData, setFormData] = useState({
    mock: "",
    classroom: "",
    coffeeNLearn: "",
    muleSoftMeetups: "",
    muleSoftQuizRank: "",
    remarks: "",
    mentors: "",
    project: "",
    selfEvaluation: "",
    futureGoal: "",
  });

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    setIsListLoading(true);
    getApi(GET_EMPLOYEE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setEmployeeList(res.data.data);
          setIsListLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsListLoading(false);
      });
  };

  const optionList = employeeList?.map((item) => ({
    value: item.id,
    label: item.empName,
    type: item.employeeType,
  }));

  const Option = (props) => {
    const { data } = props;
    const { label, type } = data;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{label}</span>
          <span style={{ fontSize: "12px", color: "gray" }}>({type})</span>
        </div>
      </components.Option>
    );
  };

  const handleChangeEmployee = async (e) => {
    setIsListLoading(true);
    setSelectedEmployee({});
    handleReset();
    let employeeId = e.value;
    setSelectedEmployeeSelect(e);
    let data = employeeList.filter((item) => item.id === Number(employeeId));
    setSelectedEmployee(data?.[0]);
    await getApi(
      API_PERFORMANCE_POINTS + `?employeeId=${employeeId}`,
      accessToken
    )
      .then((res) => {
        setEvaluatePoint(res?.data?.data);

        let hrPerformance = res?.data?.data?.hrPerformance;
        setFormData({
          mock: hrPerformance?.mock,
          classroom: hrPerformance?.classroom,
          coffeeNLearn: hrPerformance?.coffeeNLearn,
          muleSoftMeetups: hrPerformance?.muleSoftMeetups,
          muleSoftQuizRank: hrPerformance?.muleSoftMeetups,
          remarks: hrPerformance?.remarks,
          mentors: hrPerformance?.mentors,
          project: hrPerformance?.project,
          selfEvaluation: hrPerformance?.selfEvaluation,
          futureGoal: hrPerformance?.futureGoal,
        });
        setIsListLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsListLoading(false);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let objRequest = {
        mock: formData.mock,
        classroom: formData.classroom,
        coffeeNLearn: formData.coffeeNLearn,
        muleSoftMeetups: formData.muleSoftMeetups,
        muleSoftQuizRank: formData.muleSoftQuizRank,
        remarks: formData.remarks,
        employeeId: selectedEmployee?.id,
        mentors: formData.mentors,
        project: formData.project,
        selfEvaluation: formData.selfEvaluation,
        futureGoal: formData.futureGoal,
      };
      await postApi(API_PERFORMANCE_Evaluation, objRequest, accessToken)
        .then((res) => {
          setIsLoading(false);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: true,
          });
          handleReset();
          setSelectedEmployee({});
          setSelectedEmployeeSelect("");
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
          toast.error("Something went wrong please try again!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setFormData({
      mock: "",
      classroom: "",
      coffeeNLearn: "",
      muleSoftMeetups: "",
      muleSoftQuizRank: "",
      remarks: "",
      mentors: "",
      project: "",
      selfEvaluation: "",
      futureGoal: "",
    });
  };
  return (
    <div className="wrapper-body">
      {isListLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>Evaluate Employee</h1>
      </div>
      <div className="filters">
        <div className="actions">
          <Link
            to="/dashboard/evaluationView"
            className="export theme-button"
            // onClick={handleExport}
          >
            View
          </Link>
        </div>
      </div>
      <div className="card">
        <form id="assign-asset">
          <h3 className="heading">Employee</h3>
          <div className="inputs">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <div className="dropdown-container">
                  <Select
                    options={optionList}
                    components={{ Option }}
                    onChange={handleChangeEmployee}
                    value={selectedEmployeeSelect}
                    placeholder="Select employee"
                    isSearchable={true}
                    isDisabled={employeeList?.length === 0}
                    isRequired={true}
                  />
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </form>
      </div>

      {Object.keys(selectedEmployee).length > 0 && (
        <>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="resume custom">
                  {/* Table Headings */}
                  <thead>
                    <tr className="text-center">
                      <th>Training Review</th>
                      <th>Mentorship</th>
                      <th>Projects Related Review</th>
                    </tr>
                  </thead>
                  {/* Table Body */}
                  <tbody>
                    <tr className="text-center">
                      <td data-title="training">{evaluatePoint?.training}</td>
                      <td data-title="mentor">{evaluatePoint?.mentor}</td>
                      <td data-title="project">{evaluatePoint?.project}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <form id="assign-asset" onSubmit={handleSubmit}>
            <div className="card">
              <h3 className="heading">Evaluation Form </h3>
              <div className="inputs">
                <div className="row mb-2">
                  <div className="col-md-1"></div>
                  <div className="col-md-3">
                    <label htmlFor="mock" className="has-asterisk">
                      Mock Interviews (200)
                    </label>
                  </div>
                  <div className="col-md-2">
                    <div className="input-group">
                      <input
                        type="tel"
                        name="mock"
                        onChange={handleInputChange}
                        value={formData.mock}
                        required
                        onKeyPress={(e) => {
                          const isDigit = /\d/.test(e.key);
                          if (
                            !isDigit ||
                            parseInt(e.target.value + e.key) > 200
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                        }}
                        maxLength={3}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="classroom" className="has-asterisk">
                      ClassRoom Session (200)
                    </label>
                  </div>
                  <div className="col-md-2">
                    <div className="input-group">
                      <input
                        type="tel"
                        name="classroom"
                        onChange={handleInputChange}
                        value={formData.classroom}
                        required
                        onKeyPress={(e) => {
                          const isDigit = /\d/.test(e.key);
                          if (
                            !isDigit ||
                            parseInt(e.target.value + e.key) > 200
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                        }}
                        maxLength={3}
                      />
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-1"></div>
                  <div className="col-md-3">
                    <label htmlFor="coffeeNLearn" className="has-asterisk">
                      CoffeeNLearn (300)
                    </label>
                  </div>
                  <div className="col-md-2">
                    <div className="input-group">
                      <input
                        type="tel"
                        name="coffeeNLearn"
                        onChange={handleInputChange}
                        value={formData.coffeeNLearn}
                        required
                        onKeyPress={(e) => {
                          const isDigit = /\d/.test(e.key);
                          if (
                            !isDigit ||
                            parseInt(e.target.value + e.key) > 300
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                        }}
                        maxLength={3}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="muleSoftQuizRank" className="has-asterisk">
                      MuleSoft Quiz (300)
                    </label>
                  </div>
                  <div className="col-md-2">
                    <div className="input-group">
                      <input
                        type="tel"
                        name="muleSoftQuizRank"
                        onChange={handleInputChange}
                        value={formData.muleSoftQuizRank}
                        required
                        onKeyPress={(e) => {
                          const isDigit = /\d/.test(e.key);
                          if (
                            !isDigit ||
                            parseInt(e.target.value + e.key) > 300
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                        }}
                        maxLength={3}
                      />
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-1"></div>
                  <div className="col-md-3">
                    <label htmlFor="muleSoftMeetups" className="has-asterisk">
                      Meetups (300)
                    </label>
                  </div>
                  <div className="col-md-2">
                    <div className="input-group">
                      <input
                        type="tel"
                        name="muleSoftMeetups"
                        onChange={handleInputChange}
                        value={formData.muleSoftMeetups}
                        required
                        onKeyPress={(e) => {
                          const isDigit = /\d/.test(e.key);
                          if (
                            !isDigit ||
                            parseInt(e.target.value + e.key) > 300
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                        }}
                        maxLength={3}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="mentors" className="has-asterisk">
                      Mentorship (300)
                    </label>
                  </div>
                  <div className="col-md-2">
                    <div className="input-group">
                      <input
                        type="tel"
                        name="mentors"
                        onChange={handleInputChange}
                        value={formData.mentors}
                        required
                        onKeyPress={(e) => {
                          const isDigit = /\d/.test(e.key);
                          if (
                            !isDigit ||
                            parseInt(e.target.value + e.key) > 300
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                        }}
                        maxLength={3}
                      />
                    </div>
                  </div>

                  <div className="col-md-1"></div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-1"></div>
                  <div className="col-md-3">
                    <label htmlFor="project" className="has-asterisk">
                      Projects (300)
                    </label>
                  </div>
                  <div className="col-md-2">
                    <div className="input-group">
                      <input
                        type="tel"
                        name="project"
                        onChange={handleInputChange}
                        value={formData.project}
                        required
                        onKeyPress={(e) => {
                          const isDigit = /\d/.test(e.key);
                          if (
                            !isDigit ||
                            parseInt(e.target.value + e.key) > 300
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                        }}
                        maxLength={3}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="selfEvaluation" className="has-asterisk">
                      Self Evaluation (300)
                    </label>
                  </div>
                  <div className="col-md-2">
                    <div className="input-group">
                      <input
                        type="tel"
                        name="selfEvaluation"
                        onChange={handleInputChange}
                        value={formData.selfEvaluation}
                        required
                        onKeyPress={(e) => {
                          const isDigit = /\d/.test(e.key);
                          if (
                            !isDigit ||
                            parseInt(e.target.value + e.key) > 300
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                        }}
                        maxLength={3}
                      />
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-1"></div>
                  <div className="col-md-3">
                    <label htmlFor="futureGoal" className="has-asterisk">
                      Future Goals (300)
                    </label>
                  </div>
                  <div className="col-md-2">
                    <div className="input-group">
                      <input
                        type="tel"
                        name="futureGoal"
                        onChange={handleInputChange}
                        value={formData.futureGoal}
                        required
                        onKeyPress={(e) => {
                          const isDigit = /\d/.test(e.key);
                          if (
                            !isDigit ||
                            parseInt(e.target.value + e.key) > 300
                          ) {
                            e.preventDefault();
                          }
                        }}
                        maxLength={3}
                        onPaste={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="remarks" className="has-asterisk">
                      Remarks
                    </label>
                  </div>
                  <div className="col-md-2">
                    <div className="input-group">
                      <textarea
                        rows={4}
                        cols={5}
                        name="remarks"
                        value={formData.remarks}
                        onChange={handleInputChange}
                        required
                        onKeyPress={(e) => {
                          // Prevent adding space at the beginning
                          if (
                            e.charCode === 32 &&
                            e.target.selectionStart === 0
                          ) {
                            e.preventDefault();
                          }

                          // Regular expression to allow only letters, numbers, and spaces
                          const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                          // Check if the character is allowed
                          const char = String.fromCharCode(e.charCode);
                          if (!allowedCharsRegex.test(char)) {
                            e.preventDefault(); // Prevent non-alphanumeric character input
                          }
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                </div>
                {/* <div className="row ">
                  <div className="col-md-2"></div>
                  <div className="col-md-2 ">
                    <label htmlFor="reason">Reason</label>
                  </div>
                  <div className="col-md-5">
                    <div className="input-group">
                      <textarea
                        rows={4}
                        cols={5}
                        name="reason"
                        value={formData.reason}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-3"></div>
                </div>
                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-2">
                    <label htmlFor="project">Project</label>
                  </div>
                  <div className="col-md-5">
                    <div className="input-group">
                      <input type="text" />
                    </div>
                  </div>
                  <div className="col-md-3"></div>
                </div> */}
              </div>
              <br />

              <div className="action text-center">
                <button className="btn btn-secondary" onClick={handleReset}>
                  Reset
                </button>
                <button
                  className={
                    isLoading
                      ? "theme-button disabled ms-2 "
                      : "theme-button ms-2 "
                  }
                  type="submit"
                >
                  {isLoading ? (
                    <ThreeDots
                      height="15"
                      width="25"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </form>
        </>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default EvaluateEmployee;
