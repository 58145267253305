import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { getApi, putApi } from "../../utils/api";
import {
  LEAVE_APPROVE_HR,
  LEAVE_BULK_APPROVE_HR,
  LEAVE_LIST,
} from "../../config/Endpoints";
import { ThreeDots } from "react-loader-spinner";
import { toggleMenu } from "../../utils/helpers";
import Pagination from "../../components/Pagination";
import { getUser } from "../../utils/Storage";
import { toast } from "react-toastify";
import moment from "moment";

const FinalApproveLeave = () => {
  const [leaveList, setLeaveList] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedLeave, setSelectedLeave] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBulk, setIsLoadingBulk] = useState(false);
  const [leaveAction, setLeaveAction] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("PENDING");
  const [reason, setReason] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "",
  });
  const [selectedItems, setSelectedItems] = useState([]);

  const accessToken = Cookies.get("accessToken");

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const userData = JSON.parse(getUser("userData"));

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, limit, selectedStatus]);

  const fetchList = () => {
    setIsLoading(true);
    getApi(
      LEAVE_APPROVE_HR +
        `?status=${selectedStatus}&pageSize=${limit}&page=${currentPage}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          setLeaveList(res.data?.data);
          setFilteredItems(res.data?.data);
          setTotalPages(res.data?.totalPages);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleReset = () => {
    setReason("");
    setLeaveAction("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await putApi(
        LEAVE_APPROVE_HR +
          `?leaveId=${selectedLeave.laveId}&Status=${leaveAction}&comment=${reason}`,
        {},
        accessToken
      )
        .then((res) => {
          setIsLoading(false);
          let dataRes = res?.data;
          const modalClose =
            document.getElementsByClassName("cancel-final-leave")[0];
          modalClose.click();
          toast.success(dataRes.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchList();
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log(e);
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  // Bulk Submit
  const handleBulkSubmit = async (e, status) => {
    e.preventDefault();
    try {
      setIsLoadingBulk(true);
      await putApi(
        LEAVE_BULK_APPROVE_HR + `?Status=${status}&comment=`,
        selectedItems,
        accessToken
      )
        .then((res) => {
          setIsLoadingBulk(false);
          let dataRes = res?.data;
          console.log(dataRes);
          if (dataRes.message === "Successfully updated.") {
            setSelectedItems([]);
          }
          toast.success(dataRes.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchList();
        })
        .catch((e) => {
          setIsLoadingBulk(false);
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log(e);
        });
    } catch (error) {
      setIsLoadingBulk(false);
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = leaveList.filter((item) =>
      item.employeeName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredItems].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredItems(sortedData);
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>Approve Leave </h1>
        <div className="total">
          Total Leave{filteredItems?.length > 1 ? "s" : ""} :{" "}
          <span>{filteredItems && filteredItems?.length}</span>
        </div>
      </div>

      {/* Filters */}
      <div className="filters">
        {/* <form action="index.html" id="form_filters" className="d-f"> */}
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Employee Name."
            value={searchTerm}
            onChange={handleSearch}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>

        {/* <button type="submit" form="form_filters">
        Search
      </button> */}
        {/* </form> */}
        <div className="search">
          <select
            id="status"
            required
            className="form-select"
            name="status"
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
            }}
          >
            {/* <option value="" disabled>
              Select Status
            </option> */}
            <option value="PENDING">Pending</option>
            <option value="APPROVED">Approved</option>
            <option value="REJECTED">Rejected</option>
            {/* <option value="HR_APPROVED">HR Approved</option> */}
            {/* <option value="VERIFIED">Verified</option> */}
            <option value="WITHDRAW">Withdrawn</option>
          </select>
        </div>
      </div>

      {/* Data Table */}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  {/* {selectedStatus === "PENDING" && (
                    <th>
                      <input
                        type="checkbox"
                        checked={
                          filteredItems.length > 0 &&
                          selectedItems.length === filteredItems.length
                        }
                        onChange={(e) => {
                          const checked = e.target.checked;
                          // Select or deselect all checkboxes
                          setSelectedItems(
                            checked
                              ? filteredItems.map((item) => item.laveId)
                              : []
                          );
                        }}
                      />
                    </th>
                  )} */}
                  <th>S.No.</th>
                  <th>
                    Employee
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("employeeName")}
                    />
                  </th>
                  <th>
                    From
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("startDate")}
                    />
                  </th>
                  <th>
                    To
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("endDate")}
                    />
                  </th>
                  <th>
                    Leave ID
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("laveId")}
                    />
                  </th>
                  <th>
                    Reason
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("reasonOfLeave")}
                    />
                  </th>
                  <th>
                    Type
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("typeOfLeave")}
                    />
                  </th>
                  <th>
                    Manager
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("approverName")}
                    />
                  </th>
                  <th>
                    Approved Date
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("firstApprovalDateTime")}
                    />
                  </th>
                  <th>
                    Approver Comment
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("firstApprovalComment")}
                    />
                  </th>
                  <th>
                    Leave Count
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("noOfDays")}
                    />
                  </th>

                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems?.map((item, index) => (
                  <tr key={item.laveId}>
                    {/* {selectedStatus === "PENDING" && (
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedItems.includes(item.laveId)}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            if (checked) {
                              setSelectedItems((prev) => [
                                ...prev,
                                item.laveId,
                              ]);
                            } else {
                              setSelectedItems((prev) =>
                                prev.filter((id) => id !== item.laveId)
                              );
                            }
                          }}
                        />
                      </td>
                    )} */}
                    <td>{index + 1}</td>
                    <td>{item.employeeName}</td>
                    <td>{item.startDate}</td>
                    <td>{item.endDate}</td>
                    <td>{item.laveId}</td>
                    <td style={{ maxWidth: "250px" }}>{item.reasonOfLeave}</td>
                    <td>{item.typeOfLeave}</td>
                    <td>{item.approverName}</td>
                    <td>
                      {item.firstApprovalDateTime ? (
                        moment(item.firstApprovalDateTime).format("LLL")
                      ) : (
                        <span className="text-center">-</span>
                      )}
                    </td>
                    <td>
                      {item.firstApprovalComment ? (
                        item.firstApprovalComment
                      ) : (
                        <span className="text-center">-</span>
                      )}
                    </td>
                    <td>{item.noOfDays}</td>
                    <td>
                      {item.leaveFinalStatus === "WITHDRAW"
                        ? "WITHDRAWN"
                        : item.leaveFinalStatus}
                    </td>
                    <td>
                      {item.leaveFinalStatus === "PENDING" ? (
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropLeave"
                          onClick={() => setSelectedLeave(item)}
                        >
                          View
                        </a>
                      ) : (
                        <span className="text-muted">-</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="row">
            <div className="col-md-3">
              <div>
                {/* Dropdown for selecting items per page */}
                <label htmlFor="itemsPerPage">Items per page </label>
                <select
                  id="itemsPerPage"
                  onChange={handleLimitChange}
                  value={limit}
                  className="border ms-3 w-25  p-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                </select>
              </div>
            </div>
            <div className="col-md-9">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}
      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center mt-5">
          No Record Available
        </div>
      )}

      {/* Action Button */}
      {/* {selectedItems.length > 0 && (
        <div className="justify-content-center modal-footer mt-3">
          <button
            type="button"
            className={
              isLoadingBulk
                ? "theme-button bg-success disabled "
                : "theme-button bg-success"
            }
            disabled={isLoadingBulk}
            onClick={(e) => handleBulkSubmit(e, "APPROVED")}
          >
            Approve
          </button>
          <button
            type="button"
            className={
              isLoadingBulk
                ? "theme-button bg-danger mx-3 w-35 "
                : "theme-button bg-danger mx-3 w-35 disabled "
            }
            onClick={(e) => handleBulkSubmit(e, "REJECTED")}
            disabled={isLoadingBulk}
          >
            Reject
          </button>
        </div>
      )} */}

      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      <div
        className="modal fade"
        id="staticBackdropLeave"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5
                  className="modal-title text-center flex"
                  id="staticBackdropLabel"
                >
                  Approve Leave Action
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleReset}
                ></button>
              </div>
              <div className="modal-body">
                <div className="table-responsive">
                  <table className="resume custom">
                    {/* Table Headings */}
                    <thead>
                      <tr>
                        <th>Employee</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Reason</th>
                        <th>Type</th>
                        <th>Leave Count</th>
                      </tr>
                    </thead>

                    {/* Table Body */}
                    <tbody>
                      <tr>
                        <td>{selectedLeave.employeeName}</td>
                        <td>{selectedLeave.startDate}</td>
                        <td>{selectedLeave.endDate}</td>
                        <td>{selectedLeave.reasonOfLeave}</td>
                        <td>{selectedLeave.typeOfLeave}</td>
                        <td>{selectedLeave.noOfDays}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="leaveAction" className="has-asterisk">
                      Action
                    </label>
                  </div>
                  <div className="col-md-4">
                    <select
                      id="leaveAction"
                      className="border"
                      required
                      name="leaveAction"
                      value={leaveAction}
                      onChange={(e) => setLeaveAction(e.target.value)}
                    >
                      <option value="">Select Action</option>
                      <option value="APPROVED">Approve</option>
                      <option value="REJECTED">Reject</option>
                    </select>
                  </div>
                  <div className="col-md-2"></div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="reason" className="has-asterisk">
                      Reason
                    </label>
                  </div>
                  <div className="col-md-4">
                    <textarea
                      value={reason}
                      rows={3}
                      cols={5}
                      required
                      className="border"
                      onChange={(e) => setReason(e.target.value)}
                      onKeyPress={(e) => {
                        // Prevent adding space at the beginning
                        if (
                          e.charCode === 32 &&
                          e.target.selectionStart === 0
                        ) {
                          e.preventDefault();
                        }

                        // Regular expression to allow only letters, numbers, and spaces
                        const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                        // Check if the character is allowed
                        const char = String.fromCharCode(e.charCode);
                        if (!allowedCharsRegex.test(char)) {
                          e.preventDefault(); // Prevent non-alphanumeric character input
                        }
                      }}
                    ></textarea>
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>

              <div className="justify-content-center modal-footer">
                <button
                  type="submit"
                  className={
                    isLoading ? "theme-button disabled " : "theme-button "
                  }
                >
                  {isLoading ? (
                    <ThreeDots
                      height="20"
                      width="53"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  type="button"
                  className="theme-button bg-grey mx-3 w-35 cancel-final-leave"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalApproveLeave;
