import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker"; // Make sure to import your DatePicker library

const CommonDatePicker = ({
  minDate,
  maxDate,
  selectedDate,
  setSelectedDate,
}) => {
  return (
    <DatePicker
      required
      className="border ps-4"
      showIcon
      toggleCalendarOnIconClick
      selected={selectedDate}
      onChange={(date) => setSelectedDate(moment(date).format("YYYY-MM-DD"))}
      minDate={minDate} // Set the minimum date
      maxDate={maxDate}
      dateFormat="MM-dd-yyyy" // Set the desired date format
      placeholderText="MM-DD-YYYY" // Placeholder text
      onKeyDown={(e) => e.preventDefault()}
    />
  );
};

export default CommonDatePicker;
