import { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import { generateYear, generateYearAll, toggleMenu } from "../../utils/helpers";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import CreateHoliday from "./CreateHoliday";
import { API_HOLIDAY, API_HOLIDAY_DELETE } from "../../config/Endpoints";
import { deleteApi, getApi } from "../../utils/api";
import { toast } from "react-toastify";

const HolidayManagement = () => {
  const [holidayList, setHolidayList] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedHolidayId, setSelectedHolidayId] = useState("");
  const [selectedHoliday, setSelectedHoliday] = useState({});
  const accessToken = Cookies.get("accessToken");
  const [year, setYear] = useState(new Date().getFullYear());
  const [status, setStatus] = useState("pending");
  const [yearList] = useState(generateYearAll(2024));
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "",
  });
  const handleViewDetails = (data) => {
    setSelectedHoliday(data);
  };

  const handleHolidayDelete = (id) => {
    setSelectedHolidayId(id);
  };

  useEffect(() => {
    fetchList();
  }, [year, status]);

  const fetchList = () => {
    setisLoading(true);
    getApi(API_HOLIDAY + `?year=${year}&status=${status}`, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setHolidayList(res.data.data.data);
          setFilteredItems(res.data.data.data);
          setisLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setisLoading(false);
      });
  };

  const handleDelete = async () => {
    setIsLoadingDelete(true);
    deleteApi(API_HOLIDAY_DELETE + `?id=${selectedHolidayId}`, accessToken)
      .then(async (res) => {
        if (res.status === 200) {
          setIsLoadingDelete(false);
          const modalClose = document.getElementsByClassName(
            "cancel-modal-holiday-delete"
          )[0];
          modalClose.click();
          await fetchList();
          toast.success(
            res.data.message ? res.data.message : "Successfully Deleted.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      })
      .catch((e) => {
        toast.error(e?.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoadingDelete(false);
      });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = holidayList.filter((item) =>
      item.nameOfHoliday.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredItems].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredItems(sortedData);
  };
  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>Holiday Management </h1>
        <div className="total">
          Total Holiday : <span>{holidayList?.length}</span>
        </div>
      </div>

      {/* Filters */}
      <div className="filters">
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Holiday"
            value={searchTerm}
            onChange={handleSearch}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>
        <div className="search">
          <select
            id="status"
            required
            className="form-select"
            name="status"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
              setSearchTerm("");
            }}
          >
            <option value="" disabled>
              Select Status
            </option>
            <option value="Pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="Rejected">Rejected</option>
          </select>
        </div>
        <div className="search">
          <select
            id="year"
            required
            name="year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          >
            {yearList &&
              yearList.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
          </select>
        </div>
        {/* Actions */}
        <div className="actions">
          <Link
            className="export theme-button"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropHoliday"
          >
            + Add Holiday
          </Link>
        </div>
      </div>

      {/* Data Table */}
      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}

      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center">
          No Record Available
        </div>
      )}

      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th>
                    ID
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("id")}
                    />
                  </th>
                  <th>
                    Holiday
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("nameOfHoliday")}
                    />
                  </th>
                  <th>Year</th>
                  <th>
                    Date
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("date")}
                    />
                  </th>
                  <th>Status</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems &&
                  filteredItems?.map((item, index) => (
                    <tr key={index}>
                      <td data-title="id">{item.id}</td>
                      <td data-title="nameOfHoliday">{item.nameOfHoliday}</td>
                      <td data-title="year">{item.year}</td>
                      <td data-title="date">{item.date}</td>
                      <td data-title="status">
                        <small
                          className={
                            " badge " +
                            (item.status.toLowerCase() === "pending" &&
                              " badge-warning ") +
                            (item.status.toLowerCase() === "rejected" &&
                              " badge-danger ") +
                            (item.status.toLowerCase() === "approved" &&
                              " badge-success ")
                          }
                        >
                          {item.status === "0"
                            ? "Pending"
                            : item.status.toUpperCase()}
                        </small>
                      </td>

                      <td className="text-center">
                        {item.status.toLowerCase() === "pending" && (
                          <a
                            href="javascript:void(0)"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdropHoliday"
                            onClick={() => handleViewDetails(item)}
                            className="fw-bolder"
                          >
                            Edit
                          </a>
                        )}

                        <a
                          href="javascript:void(0)"
                          onClick={() => handleHolidayDelete(item.id)}
                          className="ms-3 text-danger fw-bolder"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropDependentDelete"
                        >
                          Delete
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      <div
        className="modal fade"
        id="staticBackdropHoliday"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <CreateHoliday
              selectedHoliday={selectedHoliday}
              setSelectedHoliday={setSelectedHoliday}
              fetchList={fetchList}
            />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdropDependentDelete"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body">
              <h5 className="text-center">
                Are you sure you want to delete this holiday?
              </h5>
            </div>
            <div className="justify-content-center modal-footer">
              <button
                type="button"
                className={
                  isLoadingDelete ? "theme-button disabled " : "theme-button "
                }
                onClick={handleDelete}
              >
                {isLoadingDelete ? (
                  <ThreeDots
                    height="20"
                    width="53"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  "Yes"
                )}
              </button>
              <button
                type="button"
                className="theme-button bg-grey mx-3 w-35 cancel-modal-holiday-delete"
                data-bs-dismiss="modal"
                onClick={() => {
                  setSelectedHolidayId("");
                  setIsLoadingDelete(false);
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default HolidayManagement;
