import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { getApi } from "../../utils/api";
import {
  API_V2_APPROVER_ASSEST_REQUEST_LIST,
  ASSET_LIST,
} from "../../config/Endpoints";
import { ThreeDots } from "react-loader-spinner";
import { toggleMenu } from "../../utils/helpers";
import Footer from "../../components/Footer";
import AssetRequestApproveForm from "./AssetRequestApproveForm";

const ApproveAssetRequestL2 = () => {
  const [assetId, setAssetId] = useState("");
  const [assetList, setAssetList] = useState([]);
  const [requestAsset, setRequestAsset] = useState([]);
  const [assetStatusList, setAssetStatusList] = useState([]);
  const [selectedAssetRequest, setSelectedAssetRequest] = useState({});
  const [filteredItems, setFilteredItems] = useState([]);
  const [assetAvailability, setAssetAvailability] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setIsLoading(true);
    getApi(API_V2_APPROVER_ASSEST_REQUEST_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setAssetList(res.data.data);
          setFilteredItems(res.data.data);
          setRequestAsset(res.data);
          setAssetStatusList(res.data.status);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };
  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = assetList.filter((item) =>
      item.employeeName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleAsset = async (id) => {
    // Get asset list regardless of whether the asset type is the same or different
    setAssetAvailability("");
    await getApi(ASSET_LIST + `?assestTypeId=${id}`, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setAssetAvailability(res?.data?.data?.length);
        }
      })
      .catch((e) => {
        console.log(e);
        // setIsAssetLoading(false);
      });
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>Approve Asset Request (L2)</h1>
        <div className="total">
          Total Asset Requested: <span>{filteredItems?.length}</span>
        </div>
      </div>
      {/* Filters */}
      <div className="filters">
        {/* <form action="index.html" id="form_filters" className="d-f"> */}
        {/* Search By Company */}

        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Employee Name"
            value={searchTerm}
            onChange={handleSearch}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>

        {/* <button type="submit" form="form_filters">
          Search
        </button> */}
        {/* </form> */}

        {/* Actions */}
        <div className="actions">
          {/* <Link
            to="/dashboard/createAssetRequest"
            className="export theme-button"
          >
            Asset Request
          </Link> */}
        </div>
      </div>
      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      {/* Data Table */}
      {!isLoading && filteredItems?.length > 0 && (
        <div className="table-responsive">
          <table className="resume custom">
            {/* Table Headings */}
            <thead>
              <tr>
                <th>Serial No.</th>
                <th>Employee Name</th>
                <th>Asset Type</th>
                <th>Reason</th>
                <th>Requested Date</th>
                <th>Action</th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {filteredItems &&
                filteredItems?.map((item, index) => (
                  <tr key={index}>
                    <td data-title="Serial_No">{index + 1}</td>
                    <td data-title="employeeName">{item.employeeName}</td>
                    <td data-title="name">{item.assestType}</td>
                    <td data-title="reason">{item.reason}</td>
                    <td data-title="requestDate">{item.requestDate}</td>
                    <td data-title="serialNumber">
                      {requestAsset?.approver ? (
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropAssetRequest"
                          onClick={() => {
                            // handleAsset(item.assestRequestId);
                            setAssetId(item.assestRequestId);
                            setSelectedAssetRequest(item);
                          }}
                        >
                          View
                        </a>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center">
          <h4>No Record Available</h4>
        </div>
      )}{" "}
      <div
        className="modal fade  bd-example-modal-lg"
        id="staticBackdropAssetRequest"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <AssetRequestApproveForm
              assetId={assetId}
              header="Approve Asset Request (L2) Action"
              selectedAssetRequest={selectedAssetRequest}
              assetStatusList={assetStatusList}
              fetchData={fetchData}
              assetAvailability={assetAvailability}
            />
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ApproveAssetRequestL2;
