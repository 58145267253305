import { ThreeDots } from "react-loader-spinner";
import Footer from "../../components/Footer";
import Pagination from "../../components/Pagination";
import { toggleMenu } from "../../utils/helpers";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { deleteApi, getApi, putApi } from "../../utils/api";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import {
  API_ANNOUNCEMENT,
  API_ANNOUNCEMENT_ATTACHMENT,
  API_ANNOUNCEMENT_UPDATE,
} from "../../config/Endpoints";
import moment from "moment";
import * as Yup from "yup";

const CreateAnnouncement = () => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    eventType: "",
    presentedBy: "",
    startDateTime: "",
    endDateTime: "",
    venue: "",
    mode: "",
    status: "",
    file: null,
  });
  const [selectedStatus, setSelectedStatus] = useState("PENDING");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [announcementList, setAnnouncementList] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedAnnouncementId, SetSelectedAnnouncementId] = useState("");
  const [selectedAttachmentId, setSelectedAttachmentId] = useState("");
  const [selectedAnnouncement, setSelectedAnnouncement] = useState({});
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "",
  });
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRequest, setTotalRequest] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFileChange, setIsFileChange] = useState(false);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchList();
  }, [currentPage, limit, selectedStatus]);

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  const fetchList = () => {
    setIsLoading(true);
    const statusSelected = selectedStatus === "" ? "PENDING" : selectedStatus;
    getApi(
      API_ANNOUNCEMENT +
        `?pageSize=${limit}&page=${currentPage}&status=${statusSelected}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          setAnnouncementList(res?.data?.data?.data);
          setFilteredItems(res?.data?.data?.data);
          setTotalRequest(res.data.data.totalRequest);
          setTotalPages(res.data.data.totalPages);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsButtonLoading(true);
    try {
      await AnnouncementSchema.validate(
        {
          startDateTime: formData.startDateTime,
          endDateTime: formData.endDateTime,
        },
        { abortEarly: false }
      );

      const formdata = new FormData();
      formdata.append("title", formData.title);
      formdata.append("description", formData.description);
      formdata.append("eventType", formData.eventType);
      formdata.append("presentedBy", formData.presentedBy);
      formdata.append(
        "startDateTime",
        moment(formData.startDateTime).format("YYYY-MM-DDTHH:mm:ss")
      );
      formdata.append(
        "endDateTime",
        moment(formData.endDateTime).format("YYYY-MM-DDTHH:mm:ss")
      );
      formdata.append("venue", formData.venue);
      formdata.append("mode", formData.mode);
      if (formData.file) {
        formdata.append("file", formData.file);
      }
      if (formData.status || selectedAnnouncementId) {
        formdata.append("status", formData.status.toUpperCase());
        formdata.append("id", selectedAnnouncementId);
      }
      if (selectedAnnouncementId) {
        await putApi(API_ANNOUNCEMENT_UPDATE, formdata, accessToken)
          .then((res) => {
            setIsButtonLoading(false);
            handleReset();
            fetchList();
            toast.success(res?.data?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            const modalClose =
              document.getElementsByClassName("cancel-modal")[0];
            modalClose.click();
          })
          .catch((e) => {
            setIsButtonLoading(false);
            toast.error(e.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      } else {
        await putApi(API_ANNOUNCEMENT, formdata, accessToken)
          .then((res) => {
            setIsButtonLoading(false);
            toast.success(res?.data?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            const modalClose =
              document.getElementsByClassName("cancel-modal")[0];
            modalClose.click();
            handleReset();
            fetchList();
          })
          .catch((e) => {
            setIsButtonLoading(false);
            toast.error(e.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
    } catch (error) {
      const validationErrors = {};
      error?.inner?.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
      setIsButtonLoading(false);
    }
  };

  const handleReset = () => {
    setIsFileChange(false);
    setFormData({
      title: "",
      description: "",
      eventType: "",
      presentedBy: "",
      startDateTime: "",
      endDateTime: "",
      venue: "",
      mode: "",
      file: null,
    });
    if (!isFileChange && formData.eventType !== "Display Announcement") {
      document.getElementById("file-input").value = "";
    }
    setErrors({});
    SetSelectedAnnouncementId("");
    setSelectedAnnouncement({});
  };

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === "file") {
      const file = event.target.files[0];
      // Define an array of accepted file types
      const acceptedFileTypes = [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "image/jpg",
      ];
      const maxFileSize = 8 * 1024 * 1024; // 8 MB in bytes
      if (file) {
        if (file.size > maxFileSize) {
          // File size exceeds the maximum allowed size
          toast.error("File size exceeds the maximum limit of 8MB.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          event.target.value = null; // Clear the file input
        } else if (acceptedFileTypes.includes(file.type)) {
          // File type is supported
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: file,
          }));
        } else {
          // File type is not supported, show an alert
          toast.error(
            "Unsupported file type. Please upload a valid PDF, JPG, PNG, or JPEG.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          event.target.value = null; // Clear the file input
        }
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = announcementList.filter((item) =>
      item.title.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleDelete = async () => {
    setIsLoadingDelete(true);
    await deleteApi(
      API_ANNOUNCEMENT + `/${selectedAnnouncementId}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          let newArray = announcementList.filter(
            (item) => item.id !== selectedAnnouncementId
          );
          setAnnouncementList(newArray);
          setFilteredItems(newArray);
          setIsLoadingDelete(false);
          const modalClose = document.getElementsByClassName(
            "cancel-modal-dependent-delete"
          )[0];
          modalClose.click();
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoadingDelete(false);
      });
  };

  const handleDeleteAttachment = async () => {
    setIsLoadingDelete(true);
    await deleteApi(
      API_ANNOUNCEMENT_ATTACHMENT + `/${selectedAttachmentId}`,
      accessToken
    )
      .then((res) => {
        if (res) {
          setIsLoadingDelete(false);
          setIsFileChange(false);
          const modalClose = document.getElementsByClassName(
            "cancel-modal-dependent-delete-attach"
          )[0];
          modalClose.click();
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoadingDelete(false);
      });
  };

  const handleDeleteAttachmentAlert = (id) => {
    setSelectedAttachmentId(id);
  };

  const handleDeleteAlert = (id) => {
    SetSelectedAnnouncementId(id);
  };

  const handleEditAnnouncement = (item) => {
    SetSelectedAnnouncementId(item.id);
    setSelectedAnnouncement(item);
    setFormData({
      title: item?.title,
      description: item?.description,
      eventType: item?.eventType,
      presentedBy: item?.presentedBy,
      startDateTime: new Date(item?.startDateTime),
      endDateTime: new Date(item?.endDateTime),
      venue: item?.venue,
      mode: item?.mode,
      file: item?.file,
      status: selectedStatus,
    });
    setIsFileChange(item?.id ? true : false);
  };

  const getLastPDFFileName = (filePath) => {
    // Split the file path by "/"
    const parts = filePath.split("/");

    // Initialize a variable to store the last file name
    let lastFileName = null;

    // Iterate through the parts in reverse order
    for (let i = parts.length - 1; i >= 0; i--) {
      const fileName = parts[i];

      // Check if the current part ends with ".pdf"
      if (fileName.endsWith(".pdf")) {
        lastFileName = fileName;
        break; // Stop iterating once a filename with ".pdf" extension is found
      }
    }

    return lastFileName;
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredItems].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredItems(sortedData);
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1> Announcement</h1>
        <div className="total">
          Total Requests : <span>{filteredItems?.length}</span>
        </div>
      </div>

      {/* Filters */}
      <div className="filters">
        {/* <form action="index.html" id="form_filters" className="d-f"> */}
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Title"
            value={searchTerm}
            onChange={handleSearch}
            onKeyPress={(e) => {
              if (e.charCode === 32 && e.target.selectionStart === 0) {
                e.preventDefault(); // Prevent adding space at the beginning
              }
            }}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>

        <div className="search">
          <select
            id="status"
            required
            className="form-select"
            name="status"
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(
                e.target.value === "" ? "PENDING" : e.target.value
              );
              setSearchTerm("");
            }}
          >
            <option value="">Select Status</option>
            <option value="PENDING">Pending</option>
            <option value="APPROVED">Approved</option>
            <option value="REJECTED">Rejected</option>
          </select>
        </div>
        <div className="actions">
          <button
            className="export theme-button bg-success"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropRequest"
          >
            + Create Announcement
          </button>
        </div>
      </div>
      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      {/* Data Table */}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom" id="table-email-request">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th onClick={() => handleSort("id")}>
                    Id
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("title")}>
                    Title
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th>Description</th>
                  <th onClick={() => handleSort("eventType")}>
                    Event Type
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("venue")}>
                    Venue
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("mode")}>
                    Mode
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("presentedBy")}>
                    Presented By
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("startDateTime")}>
                    Start Date Time
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("endDateTime")}>
                    End Date Time
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  {/* <th>Duration</th> */}
                  <th onClick={() => handleSort("status")}>
                    State{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("owner")}>
                    Status{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th>Action</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems &&
                  filteredItems?.map((item, index) => (
                    <tr key={index}>
                      <td data-title="id">{item.id}</td>
                      <td data-title="title">{item.title}</td>
                      <td data-title="description">{item.description}</td>
                      <td data-title="eventType">{item.eventType}</td>
                      <td data-title="venue">{item.venue}</td>
                      <td data-title="mode">{item.mode}</td>
                      <td data-title="presentedBy">{item.presentedBy}</td>
                      <td data-title="startDateTime">
                        {moment(item.startDateTime).format("LLL")}
                      </td>
                      <td data-title="endDateTime">
                        {moment(item.endDateTime).format("LLL")}
                      </td>
                      {/* <td data-title="duration">{item.duration}</td> */}
                      <td data-title="status">
                        <small
                          className={`text-capitalize badge bg-${
                            item.status === "Upcoming"
                              ? "success"
                              : item.status === "Past"
                              ? "danger"
                              : "warning"
                          }`}
                        >
                          {item.status}
                        </small>
                      </td>
                      <th> {selectedStatus}</th>
                      <td>
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropRequest"
                          onClick={() => {
                            handleEditAnnouncement(item);
                          }}
                        >
                          <i class="fa fa-edit"></i>
                        </a>
                        <a
                          href="javascript:void(0)"
                          onClick={() => handleDeleteAlert(item.id)}
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropDependentDelete"
                          className="text-danger ms-2"
                        >
                          <i class="fa fa-trash"></i>
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div>
                {/* Dropdown for selecting items per page */}
                <label htmlFor="itemsPerPage">Items per page </label>
                <select
                  id="itemsPerPage"
                  onChange={handleLimitChange}
                  value={limit}
                  className="border ms-3 w-25  p-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                </select>
              </div>
            </div>
            <div className="col-md-9">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}
      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center mt-5">
          <h4>No Record Available</h4>
        </div>
      )}

      <div
        className="modal fade"
        id="staticBackdropRequest"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5
                  className="modal-title text-center flex"
                  id="staticBackdropLabel"
                >
                  {selectedAnnouncement?.id ? "Edit" : "Create"} Announcement
                </h5>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleReset}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="eventType" className="has-asterisk">
                      Event Type
                    </label>
                  </div>
                  <div className="col-md-4 ">
                    <select
                      id="eventType"
                      required
                      className="border"
                      name="eventType"
                      value={formData.eventType}
                      onChange={handleInputChange}
                    >
                      <option value="">Select event type</option>
                      <option value="Classroom Session">
                        Classroom Session
                      </option>
                      <option value="Coffee & Learn Session">
                        Coffee & Learn Session
                      </option>
                      <option value="MuleSoft Meetups">MuleSoft Meetups</option>
                      <option value="Salesforce Session">
                        Salesforce Session
                      </option>
                      <option value="Display Announcement">
                        Display Announcement
                      </option>
                    </select>
                  </div>
                  <div className="col-md-2"></div>
                </div>
                <div className="row  mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label className="has-asterisk">Title</label>
                  </div>

                  <div className="col-md-4">
                    <input
                      type="text"
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                      placeholder="Enter title"
                      maxLength={150}
                      required
                      className="border"
                      onKeyPress={(e) => {
                        if (
                          e.charCode === 32 &&
                          e.target.selectionStart === 0
                        ) {
                          e.preventDefault(); // Prevent adding space at the beginning
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-2"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="startDateTime" className="has-asterisk">
                      Start Date Time
                    </label>
                  </div>
                  <div className="col-md-4">
                    <DatePicker
                      selected={formData.startDateTime}
                      toggleCalendarOnIconClick
                      placeholderText="YYYY-MM-DDTHH:mm:ss"
                      onChange={(date) => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          startDateTime: date,
                        }));
                      }}
                      minDate={new Date()}
                      showTimeSelect
                      filterTime={filterPassedTime}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className="border"
                      required
                    />
                    {errors.startDateTime && (
                      <small className="text-danger">
                        {errors.startDateTime}
                      </small>
                    )}
                  </div>
                  <div className="col-md-2"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="endDateTime" className="has-asterisk">
                      End Date Time
                    </label>
                  </div>
                  <div className="col-md-4">
                    <DatePicker
                      toggleCalendarOnIconClick
                      selected={formData.endDateTime}
                      placeholderText="YYYY-MM-DDTHH:mm:ss"
                      name="endDateTime"
                      minDate={new Date()}
                      onChange={(date) => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          endDateTime: date,
                        }));
                      }}
                      required
                      showTimeSelect
                      filterTime={filterPassedTime}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className="border"
                    />
                    {errors.endDateTime && (
                      <small className="text-danger">
                        {errors.endDateTime}
                      </small>
                    )}
                  </div>
                  <div className="col-md-2"></div>
                </div>
                {formData.eventType !== "Display Announcement" && (
                  <>
                    <div className="row mt-3">
                      <div className="col-md-2"></div>
                      <div className="col-md-4">
                        <label className="has-asterisk">Presented By</label>
                      </div>

                      <div className="col-md-4">
                        <input
                          type="text"
                          name="presentedBy"
                          value={formData.presentedBy}
                          maxLength={30}
                          onChange={handleInputChange}
                          placeholder="Enter presenter name"
                          required
                          className="border"
                          onKeyPress={(e) => {
                            const inputValue = String.fromCharCode(e.charCode);

                            // Check if the character is a space at the beginning
                            if (
                              (inputValue === " " || inputValue === ",") &&
                              e.target.selectionStart === 0
                            ) {
                              e.preventDefault(); // Prevent adding space at the beginning
                            }

                            // Check if the character is an alphabetic character, space, or comma
                            if (
                              /^[a-zA-Z, ]$/.test(inputValue) // Alphabets, space, or comma
                            ) {
                              // Allow the character to be typed
                            } else {
                              e.preventDefault(); // Prevent adding the character
                            }
                          }}
                        />
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-2"></div>
                      <div className="col-md-4">
                        <label htmlFor="venue" className="has-asterisk">
                          Venue
                        </label>
                      </div>
                      <div className="col-md-4 ">
                        <input
                          type="text"
                          name="venue"
                          className="border"
                          value={formData.venue}
                          onChange={handleInputChange}
                          placeholder="Enter venue"
                          maxLength={160}
                          required
                        />
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-2"></div>
                      <div className="col-md-4">
                        <label htmlFor="mode" className="has-asterisk">
                          Mode
                        </label>
                      </div>
                      <div className="col-md-4 ">
                        <input
                          type="text"
                          name="mode"
                          className="border"
                          value={formData.mode}
                          onChange={handleInputChange}
                          placeholder="Enter mode"
                          maxLength={160}
                          required
                        />
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-2"></div>
                      <div className="col-md-4">
                        <label htmlFor="file">File</label>
                      </div>
                      <div className="col-md-4 ">
                        {selectedAnnouncement.id ? (
                          <>
                            {isFileChange &&
                            getLastPDFFileName(
                              selectedAnnouncement?.attachment
                            ) !== null ? (
                              <div className="policy-uploaded-file border p-2 ">
                                <div className="col-md-10">
                                  <span className="span-policy">
                                    {getLastPDFFileName(
                                      selectedAnnouncement?.attachment
                                    )}
                                  </span>
                                </div>
                                <div className="col-md-2">
                                  <a
                                    className="m-5 text-danger fw-bolder policy-file-delete"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdropDependentDeleteFile"
                                    onClick={() =>
                                      handleDeleteAttachmentAlert(
                                        selectedAnnouncement.id
                                      )
                                    }
                                  >
                                    <i class="fa fa-trash"></i>
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <input
                                type="file"
                                name="file"
                                id="file-input"
                                onChange={handleInputChange}
                                className="border"
                              />
                            )}
                          </>
                        ) : (
                          <input
                            type="file"
                            name="file"
                            id="file-input"
                            onChange={handleInputChange}
                            className="border"
                          />
                        )}

                        <small className="text-danger pt-0">
                          Note: Please upload valid{" "}
                          <strong>PDF, JPG, PNG, or JPEG</strong> file only.
                        </small>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                  </>
                )}
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="description" className="has-asterisk">
                      Description
                    </label>
                  </div>
                  <div className="col-md-4 ">
                    <textarea
                      rows={3}
                      name="description"
                      className="border"
                      placeholder="Enter description"
                      value={formData.description}
                      onChange={handleInputChange}
                      maxLength={160}
                      onKeyPress={(e) => {
                        // Prevent adding space at the beginning
                        if (
                          e.charCode === 32 &&
                          e.target.selectionStart === 0
                        ) {
                          e.preventDefault();
                        }

                        // Regular expression to allow only letters, numbers, and spaces
                        const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                        // Check if the character is allowed
                        const char = String.fromCharCode(e.charCode);
                        if (!allowedCharsRegex.test(char)) {
                          e.preventDefault(); // Prevent non-alphanumeric character input
                        }
                      }}
                      required
                    ></textarea>
                  </div>
                  <div className="col-md-2"></div>
                </div>
                {selectedAnnouncement?.id && (
                  <div className="row mt-3">
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                      <label htmlFor="status" className="has-asterisk">
                        Status
                      </label>
                    </div>
                    <div className="col-md-4 ">
                      <select
                        id="status"
                        className="border"
                        name="status"
                        value={formData.status}
                        required
                        onChange={handleInputChange}
                      >
                        <option value="">Select Status</option>
                        <option value="PENDING">Pending</option>
                        <option value="APPROVED">Approved</option>
                        <option value="REJECTED">Rejected</option>
                      </select>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                )}
              </div>

              <div className="justify-content-center modal-footer">
                <button
                  type="submit"
                  className={
                    isButtonLoading ? "theme-button disabled " : "theme-button "
                  }
                >
                  {isButtonLoading ? (
                    <ThreeDots
                      height="20"
                      width="53"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <>{selectedAnnouncement?.id ? "Update" : "Submit"}</>
                  )}
                </button>
                <button
                  type="button"
                  className="theme-button bg-grey mx-3 w-35 cancel-modal"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdropDependentDelete"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body">
              <h5 className="text-center">
                Are you sure you want to delete this announcement?
              </h5>
            </div>
            <div className="justify-content-center modal-footer">
              <button
                type="button"
                className={
                  isLoadingDelete ? "theme-button disabled " : "theme-button "
                }
                onClick={handleDelete}
              >
                {isLoadingDelete ? (
                  <ThreeDots
                    height="20"
                    width="53"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  "Yes"
                )}
              </button>
              <button
                type="button"
                className="theme-button bg-grey mx-3 w-35 cancel-modal-dependent-delete"
                data-bs-dismiss="modal"
                onClick={() => SetSelectedAnnouncementId("")}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdropDependentDeleteFile"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-full-height modal-right">
          <div className="modal-content">
            <div className="modal-body">
              <h5 className="text-center">
                Are you sure you want to delete attachment from announcement?
              </h5>
            </div>
            <div className="justify-content-center modal-footer">
              <button
                type="button"
                className={
                  isLoadingDelete ? "theme-button disabled " : "theme-button "
                }
                onClick={handleDeleteAttachment}
              >
                {isLoadingDelete ? (
                  <ThreeDots
                    height="20"
                    width="53"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  "Yes"
                )}
              </button>
              <button
                type="button"
                className="theme-button bg-grey mx-3 w-35 cancel-modal-dependent-delete-attach"
                data-bs-dismiss="modal"
                onClick={() => setSelectedAttachmentId("")}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default CreateAnnouncement;

const AnnouncementSchema = Yup.object().shape({
  startDateTime: Yup.date().required("Start Date Time is required"),
  endDateTime: Yup.date()
    .required("End Date Time is required")
    .when("startDateTime", (startDateTime, schema) => {
      return schema.test({
        test: (endDateTime) => {
          return new Date(endDateTime) >= new Date(startDateTime);
        },
        message:
          "'End Date Time' must be later than or equal to 'start Date Time'",
      });
    }),
});
