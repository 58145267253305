import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API_VALIDATE_TOKEN, SIGN_UP } from "../../config/Endpoints";
import Cookies from "js-cookie";
import { postApi } from "../../utils/api";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import * as Yup from "yup";
import MultiStepProgressBar from "./CreateEmployee/MultiStepProgressBar";
import UploadDocument from "./CreateEmployee/UploadDocument";
import ReviewEmployeeData from "./CreateEmployee/ReviewEmployeeData";
import {
  AADHAAR_REGEX,
  PAN_REGEX,
  isCardNumberValid,
} from "../../utils/helpers";
import Footer from "../../components/Footer";

const CreateEmployee = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    bloodGroup: "",
    dob: "",
    aadharNumber: "",
    panCardNumber: "",
    passportNumber: "",
    gender: "",
    joiningDate: "",
    maritalStatus: "",
    anniversaryDate: "",
    phoneNumber: "",
    alternateNumber: "",
    presentAddress: "",
    permanentAddress: "",
    personalEmail: "",
    alternateName: "",
    relationWithAlternateNo: "",
  });
  const [error, setError] = useState("");
  const [errors, setErrors] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [page, setPage] = useState("pageone");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Extract the 'token' parameter from the search parameters
  const token = searchParams.get("token");

  useEffect(() => {
    const modalClose = document.getElementsByClassName("click-modal")[0];
    modalClose.click();
  }, []);

  const handleSubmitValidate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await schemaValidate.validate({ email: email }, { abortEarly: false });
      await postApi(
        API_VALIDATE_TOKEN + `?Token=${token}&email=${email}`,
        accessToken
      )
        .then((res) => {
          if (res.status === 200) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              personalEmail: email,
            }));
            const modalClose = document.getElementsByClassName("btn-close")[0];
            modalClose.click();
            // toast.success(res?.data?.message, {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
            setIsLoading(false);
          }
        })
        .catch((e) => {
          toast.error(
            e?.response?.data?.message
              ? e.response.data.message
              : "Something went wrong please try again!",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          setIsLoading(false);
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
      setIsLoading(false);
    }
  };

  const nextPage = (page) => {
    setPage(page);
  };

  const formRef_one = useRef(null);
  const formRef_two = useRef(null);
  const formRef_three = useRef(null);
  const formRef_four = useRef(null);

  const nextPageNumber = (pageNumber) => {
    switch (pageNumber) {
      case "1":
        setPage("pageone");
        break;
      case "2":
        setPage("pagetwo");
        break;
      case "3":
        setPage("pagethree");
        break;
      case "4":
        setPage("pagefour");
        break;
      default:
        setPage("pageone");
        break;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailRegex.test(formData.personalEmail)) {
    //   return toast.error("Invalid email address, please use valid email !", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
    setIsLoading(true);
    try {
      await schema.validate(
        {
          personalEmail: formData.personalEmail,
          dob: formData.dob,
          joiningDate: formData.joiningDate,
        },
        { abortEarly: false }
      );
      let requestData = {
        aadharNum: formData.aadharNumber,
        alternateNumber: formData.alternateNumber,
        anniversaryDate: formData.anniversaryDate,
        bloodGroup: formData.bloodGroup,
        dob: formData.dob,
        doj: formData.joiningDate,
        firstName: formData.firstName,
        gender: formData.gender,
        lastName: formData.lastName,
        maritalStatus: formData.maritalStatus,
        middleName: formData.middleName,
        panNum: formData.panCardNumber,
        passportNum: formData.passportNumber,
        permanentAddress: formData.permanentAddress,
        personalEmail: formData.personalEmail,
        phoneNumber: formData.phoneNumber,
        presentAddress: formData.presentAddress,
        alternateName: formData.alternateName,
        relationWithAlternateNo: formData.relationWithAlternateNo,
        token: token,
      };

      await postApi(SIGN_UP, requestData, accessToken)
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            setIsSubmitted(true);
            setEmail("");
          } else {
            setIsError(true);
            setIsLoading(false);
            setError("Something went wrong. Please try again!");
            setTimeout(() => {
              setIsError(false);
            }, 4000);
          }
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
      setIsLoading(false);
    }
  };

  const handleManualSubmit = async (e, nextStep) => {
    e.preventDefault();
    try {
      await schema.validate(
        {
          personalEmail: formData.personalEmail,
          dob: formData.dob,
          joiningDate: formData.joiningDate,
        },
        { abortEarly: false }
      );
      setErrors("");

      let newError = {};

      const formOne = formRef_one.current;
      const formTwo = formRef_two.current;
      const formThree = formRef_three.current;
      const formFour = formRef_four.current;

      if (
        (formOne && formOne.checkValidity()) ||
        (formTwo && formTwo.checkValidity()) ||
        // (formThree && formThree.checkValidity()) ||
        (formFour && formFour.checkValidity())
      ) {
        if (
          !isCardNumberValid("AADHAAR", formData.aadharNumber) ||
          !isCardNumberValid("PAN", formData.panCardNumber)
        ) {
          if (
            !formData.aadharNumber ||
            !isCardNumberValid("AADHAAR", formData.aadharNumber)
          ) {
            newError.aadharNumber = "Invalid Aadhaar card number";
          }
          if (
            !formData.panCardNumber ||
            !isCardNumberValid("PAN", formData.panCardNumber)
          ) {
            newError.panCardNumber = "Invalid PAN card number";
          }
          setErrors(newError);
        } else {
          if (nextStep === "") {
            setIsLoading(true);
            handleSubmit(e);
          } else {
            nextPage(nextStep);
          }
        }
      } else {
        if (nextStep === "pagetwo") {
          formOne.reportValidity();
        }

        // if (nextStep === "pagethree") {
        //   formTwo.reportValidity();
        // }

        setIsLoading(false);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    setErrors("");
    if (type === "date") {
      const year = value.split("-")[0];
      if (year.length <= 4) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFormData({
      firstName: "",
      middleName: "",
      lastName: "",
      bloodGroup: "",
      dob: "",
      aadharNumber: "",
      panCardNumber: "",
      passportNumber: "",
      gender: "",
      joiningDate: "",
      maritalStatus: "",
      anniversaryDate: "",
      phoneNumber: "",
      alternateNumber: "",
      presentAddress: "",
      permanentAddress: "",
      personalEmail: "",
      alternateName: "",
      relationWithAlternateNo: "",
    });
    setIsLoading(false);
  };

  // Get today's date in the format yyyy-mm-dd
  const today = new Date().toISOString().split("T")[0];
  // Function to get the minimum selectable date (today's date - 30 days)
  function getMinDate() {
    const today = new Date();
    const minDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 30
    );
    return minDate.toISOString().split("T")[0];
  }
  return (
    <div className="wrapper-body w-100">
      {!isSubmitted && (
        // <form id="createEmployeeForm" onSubmit={handleSubmit}>
        <div className="row">
          <button
            className="click-modal"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropValidate"
            style={{ display: "none" }}
          >
            demo
          </button>
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <div className="card">
              <h3 className="heading">
                Employee Details
                <span style={{ float: "right" }}>
                  <img
                    src="https://www.caeliusconsulting.com/image/logo.svg"
                    alt="Caelius Consulting Logo"
                    width="135"
                    height="32"
                  />
                </span>
              </h3>
              {}
              <>
                <MultiStepProgressBar
                  page={page}
                  onPageNumberClick={nextPageNumber}
                />
                {
                  {
                    pageone: (
                      <form
                        id="createEmployeeForm_pageone"
                        ref={formRef_one}
                        onSubmit={(e) => handleManualSubmit(e, "pagetwo")}
                      >
                        <h6 className="heading mt-2">Personal Details</h6>
                        <div className="d-f f-w inputs gap-15">
                          <div className="input-group">
                            <label htmlFor="firstName" className="has-asterisk">
                              First Name
                            </label>
                            <input
                              type="text"
                              name="firstName"
                              value={formData.firstName}
                              onChange={handleInputChange}
                              placeholder="Ted"
                              required
                              onKeyPress={(e) => {
                                const inputValue = String.fromCharCode(
                                  e.charCode
                                );
                                if (
                                  (e.charCode === 32 &&
                                    e.target.selectionStart === 0) ||
                                  (inputValue.match(/^\d+$/) &&
                                    e.target.selectionStart === 0)
                                ) {
                                  e.preventDefault(); // Prevent adding space at the beginning  of the string or numbers only
                                }
                              }}
                              maxLength={100}
                            />
                          </div>
                          <div className="input-group">
                            <label htmlFor="middleName">Middle Name</label>
                            <input
                              type="text"
                              name="middleName"
                              value={formData.middleName}
                              onChange={handleInputChange}
                              placeholder="Evelyn"
                              maxLength={100}
                              onKeyPress={(e) => {
                                const inputValue = String.fromCharCode(
                                  e.charCode
                                );
                                if (
                                  (e.charCode === 32 &&
                                    e.target.selectionStart === 0) ||
                                  (inputValue.match(/^\d+$/) &&
                                    e.target.selectionStart === 0)
                                ) {
                                  e.preventDefault(); // Prevent adding space at the beginning  of the string or numbers only
                                }
                              }}
                            />
                          </div>
                          <div className="input-group">
                            <label htmlFor="lastName" className="has-asterisk">
                              Last Name
                            </label>
                            <input
                              type="text"
                              name="lastName"
                              value={formData.lastName}
                              onChange={handleInputChange}
                              placeholder="Mosby"
                              maxLength={100}
                              required
                              onKeyPress={(e) => {
                                const inputValue = String.fromCharCode(
                                  e.charCode
                                );
                                if (
                                  (e.charCode === 32 &&
                                    e.target.selectionStart === 0) ||
                                  (inputValue.match(/^\d+$/) &&
                                    e.target.selectionStart === 0)
                                ) {
                                  e.preventDefault(); // Prevent adding space at the beginning  of the string or numbers only
                                }
                              }}
                            />
                          </div>
                          <div className="input-group">
                            <label
                              htmlFor="personalEmail"
                              className="has-asterisk"
                            >
                              Personal Email
                            </label>
                            <input
                              type="email"
                              name="personalEmail"
                              maxLength={100}
                              value={formData.personalEmail}
                              onChange={handleInputChange}
                              placeholder="ted@gmail.com"
                              disabled
                              required
                            />
                            {errors.personalEmail && (
                              <small className="text-danger">
                                {errors.personalEmail}
                              </small>
                            )}
                          </div>
                        </div>

                        <div className="d-f f-w inputs gap-15">
                          <div className="input-group">
                            <label className="has-asterisk">Gender</label>
                            <div className="custom-checkbox small">
                              <div>
                                <input
                                  type="radio"
                                  id="male"
                                  name="gender"
                                  value="male"
                                  checked={formData.gender === "male"}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="male">Male</label>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  id="female"
                                  name="gender"
                                  value="female"
                                  checked={formData.gender === "female"}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="female">Female</label>
                              </div>

                              <div>
                                <input
                                  type="radio"
                                  id="others"
                                  name="gender"
                                  value="others"
                                  required
                                  checked={formData.gender === "others"}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="others">Others</label>
                              </div>
                            </div>
                          </div>
                          <div className="input-group">
                            <label
                              htmlFor="bloodGroup"
                              className="has-asterisk"
                            >
                              Blood Group
                            </label>
                            <select
                              id="bloodGroup"
                              value={formData.bloodGroup}
                              required
                              onChange={handleInputChange}
                              name="bloodGroup"
                            >
                              <option value="">Select Blood Group</option>
                              <option value="A+ve">A+ve</option>
                              <option value="A-ve">A-ve</option>
                              <option value="B+ve">B+ve</option>
                              <option value="B-ve">B-ve</option>
                              <option value="AB+ve">AB+ve</option>
                              <option value="AB-ve">AB-ve</option>
                              <option value="O+ve">O+ve</option>
                              <option value="O-ve">O-ve</option>
                            </select>
                          </div>
                          <div className="input-group">
                            <label htmlFor="dob" className="has-asterisk">
                              Date of Birth
                            </label>
                            <input
                              type="date"
                              required
                              name="dob"
                              value={formData.dob}
                              placeholder="08/02/2019"
                              onChange={handleInputChange}
                              max={today}
                            />
                            {errors.dob && (
                              <small className="text-danger">
                                {errors.dob}
                              </small>
                            )}
                          </div>
                        </div>

                        <div className="d-f f-w inputs gap-15">
                          <div className="input-group">
                            <label
                              htmlFor="aadharNumber"
                              className="has-asterisk"
                            >
                              Aadhar Number
                            </label>
                            <input
                              type="tel"
                              required
                              minLength="12"
                              maxLength="12"
                              name="aadharNumber"
                              value={formData.aadharNumber}
                              onChange={(e) => {
                                const input = e.target;
                                if (input.value.length < 12) {
                                  input.setCustomValidity(
                                    "Please enter a 12-digit Aadhar card number"
                                  );
                                } else if (input.value.length > 12) {
                                  input.setCustomValidity(
                                    "Aadhar card number should not exceed 12 digits"
                                  );
                                } else {
                                  input.setCustomValidity("");
                                }
                                handleInputChange(e);
                              }}
                              placeholder="XXXX-XXXX-XXXX"
                              title="Aadhar Number must be a 12-digit number"
                              onKeyPress={(e) => {
                                const isDigit = /\d/.test(e.key);
                                const isLengthValid =
                                  e.target.value.length < 13;

                                if (!isDigit || !isLengthValid) {
                                  e.preventDefault();
                                }
                              }}
                              onPaste={(e) => {
                                e.preventDefault();
                              }}
                            />
                            {errors.aadharNumber && (
                              <small className="text-danger">
                                {errors.aadharNumber}
                              </small>
                            )}
                          </div>
                          <div className="input-group">
                            <label
                              htmlFor="panCardNumber"
                              className="has-asterisk"
                            >
                              PAN Card Number
                            </label>
                            <input
                              type="text"
                              required
                              name="panCardNumber"
                              maxLength="10"
                              minLength="10"
                              value={formData.panCardNumber}
                              onChange={(e) => {
                                const input = e.target;
                                if (input.value.length < 10) {
                                  input.setCustomValidity(
                                    "Please enter a 10-digit PAN card number"
                                  );
                                } else if (input.value.length > 10) {
                                  input.setCustomValidity(
                                    "PAN card number should not exceed 10 digits"
                                  );
                                } else {
                                  input.setCustomValidity("");
                                }
                                handleInputChange(e);
                              }}
                              placeholder="XXX-XXX-XXXX"
                              onPaste={(e) => {
                                e.preventDefault();
                              }}
                              onKeyPress={(e) => {
                                if (
                                  e.charCode === 32 &&
                                  e.target.selectionStart === 0
                                ) {
                                  e.preventDefault(); // Prevent adding space at the beginning
                                }
                              }}
                            />
                            {errors.panCardNumber && (
                              <small className="text-danger">
                                {errors.panCardNumber}
                              </small>
                            )}
                          </div>
                          <div className="input-group">
                            <label htmlFor="passportNumber">
                              Passport Number
                            </label>
                            <input
                              type="text"
                              maxLength="12"
                              minLength="12"
                              name="passportNumber"
                              value={formData.passportNumber}
                              onChange={(e) => {
                                const input = e.target;
                                if (input.value.length < 12) {
                                  input.setCustomValidity(
                                    "Please enter a 12-digit Passport number"
                                  );
                                } else if (input.value.length > 12) {
                                  input.setCustomValidity(
                                    "Passport number should not exceed 12 digits"
                                  );
                                } else {
                                  input.setCustomValidity("");
                                }
                                handleInputChange(e);
                              }}
                              placeholder="XXXX-XXXX-XXXX"
                              onKeyPress={(e) => {
                                if (
                                  e.charCode === 32 &&
                                  e.target.selectionStart === 0
                                ) {
                                  e.preventDefault(); // Prevent adding space at the beginning
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="d-f f-w inputs gap-15">
                          <div className="input-group">
                            <label
                              htmlFor="joiningDate"
                              className="has-asterisk"
                            >
                              Date of Joining
                            </label>
                            <input
                              type="date"
                              name="joiningDate"
                              value={formData.joiningDate}
                              onChange={handleInputChange}
                              placeholder="08/02/2019"
                              min={getMinDate()}
                              // max={today}
                              required
                            />
                            {errors.joiningDate && (
                              <small className="text-danger">
                                {errors.joiningDate}
                              </small>
                            )}
                          </div>
                          <div className="input-group">
                            <label
                              htmlFor="maritalStatus"
                              className="has-asterisk"
                            >
                              Marital Status
                            </label>
                            <select
                              id="maritalStatus"
                              value={formData.maritalStatus}
                              required
                              onChange={handleInputChange}
                              name="maritalStatus"
                            >
                              <option value="">Select Marital Status</option>
                              <option value="Single">Single</option>
                              <option value="Married">Married</option>
                              <option value="Widowed">Widowed</option>
                              <option value="Separated">Separated</option>
                              <option value="Divorced">Divorced</option>
                            </select>
                          </div>
                          {formData.maritalStatus === "Married" && (
                            <div className="input-group">
                              <label htmlFor="" className="has-asterisk">
                                Anniversary Date
                              </label>
                              <input
                                type="date"
                                name="anniversaryDate"
                                value={formData.anniversaryDate}
                                onChange={handleInputChange}
                                placeholder="08/02/2019"
                                max={today}
                                required
                              />
                            </div>
                          )}
                        </div>
                        <div className="action d-flex justify-content-end mt-5 ">
                          <button
                            className="theme-button"
                            type="submit"
                            // onClick={() => nextPage("pagetwo")}
                          >
                            Next
                          </button>
                        </div>
                      </form>
                    ),
                    pagetwo: (
                      <form
                        id="createEmployeeForm_pagetwo"
                        ref={formRef_two}
                        onSubmit={(e) => handleManualSubmit(e, "pagefour")}
                      >
                        <h6 className="heading mt-4">Contact Details</h6>
                        <div className="d-f f-w inputs gap-15">
                          <div className="input-group">
                            <label
                              htmlFor="phoneNumber"
                              className="has-asterisk"
                            >
                              Phone Number
                            </label>
                            <input
                              type="tel"
                              required
                              maxLength="10"
                              minLength="10"
                              name="phoneNumber"
                              value={formData.phoneNumber}
                              onChange={handleInputChange}
                              placeholder="9876543210"
                              onKeyPress={(e) => {
                                const regex = /^[0-9\b]+$/;
                                if (!regex.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onPaste={(e) => {
                                e.preventDefault();
                              }}
                            />
                          </div>
                          <div className="input-group">
                            <label
                              htmlFor="alternateNumber"
                              className="has-asterisk"
                            >
                              Alternate Number
                            </label>
                            <input
                              type="tel"
                              name="alternateNumber"
                              minLength="10"
                              maxLength="10"
                              value={formData.alternateNumber}
                              onChange={handleInputChange}
                              placeholder="9876543210"
                              onKeyPress={(e) => {
                                const regex = /^[0-9\b]+$/;
                                if (!regex.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onPaste={(e) => {
                                e.preventDefault();
                              }}
                              required
                            />
                          </div>
                          <div className="input-group">
                            <label
                              htmlFor="middleName"
                              className="has-asterisk"
                            >
                              Relationship with alternate number
                            </label>
                            <select
                              id="relationWithAlternateNo"
                              name="relationWithAlternateNo"
                              value={formData.relationWithAlternateNo}
                              onChange={handleInputChange}
                              required
                            >
                              <option value="">Select Relation</option>
                              <option value="mother">Mother</option>
                              <option value="father">Father</option>
                              {(formData.maritalStatus === "Married" ||
                                formData.gender === "others") && (
                                <>
                                  {(formData.gender === "female" ||
                                    formData.gender === "others") && (
                                    <option value="husband">Husband</option>
                                  )}
                                  {(formData.gender === "male" ||
                                    formData.gender === "others") && (
                                    <option value="wife">Wife</option>
                                  )}
                                </>
                              )}
                            </select>
                          </div>
                          <div className="input-group">
                            <label
                              htmlFor="alternateName"
                              className="has-asterisk"
                            >
                              Alternate Name
                            </label>
                            <input
                              type="text"
                              name="alternateName"
                              value={formData.alternateName}
                              onChange={handleInputChange}
                              placeholder="Barney Stinson"
                              maxLength={100}
                              onKeyPress={(e) => {
                                const inputValue = String.fromCharCode(
                                  e.charCode
                                );
                                if (
                                  (e.charCode === 32 &&
                                    e.target.selectionStart === 0) ||
                                  (inputValue.match(/^\d+$/) &&
                                    e.target.selectionStart === 0)
                                ) {
                                  e.preventDefault(); // Prevent adding space at the beginning  of the string or numbers only
                                }
                              }}
                              required
                            />
                          </div>
                        </div>
                        <div className="d-f f-w inputs gap-15">
                          <div className="input-group">
                            <label
                              htmlFor="presentAddress"
                              className="has-asterisk"
                            >
                              Present Address
                            </label>
                            <textarea
                              name="presentAddress"
                              value={formData.presentAddress}
                              onChange={handleInputChange}
                              required
                              onKeyPress={(e) => {
                                // Prevent adding space at the beginning
                                if (
                                  e.charCode === 32 &&
                                  e.target.selectionStart === 0
                                ) {
                                  e.preventDefault();
                                }

                                // Regular expression to allow only letters, numbers, and spaces
                                const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                                // Check if the character is allowed
                                const char = String.fromCharCode(e.charCode);
                                if (!allowedCharsRegex.test(char)) {
                                  e.preventDefault(); // Prevent non-alphanumeric character input
                                }
                              }}
                            />
                          </div>
                          <div className="input-group">
                            <label
                              htmlFor="permanentAddress"
                              className="has-asterisk"
                            >
                              Permanent Address
                            </label>
                            <textarea
                              name="permanentAddress"
                              value={formData.permanentAddress}
                              onChange={handleInputChange}
                              required
                              onKeyPress={(e) => {
                                // Prevent adding space at the beginning
                                if (
                                  e.charCode === 32 &&
                                  e.target.selectionStart === 0
                                ) {
                                  e.preventDefault();
                                }

                                // Regular expression to allow only letters, numbers, and spaces
                                const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                                // Check if the character is allowed
                                const char = String.fromCharCode(e.charCode);
                                if (!allowedCharsRegex.test(char)) {
                                  e.preventDefault(); // Prevent non-alphanumeric character input
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="action d-flex justify-content-between mt-5">
                          <button
                            className="theme-button bg-grey me-3 "
                            onClick={() => nextPage("pageone")}
                          >
                            Previous
                          </button>
                          <button className="theme-button" type="submit">
                            Next
                          </button>
                        </div>
                      </form>
                    ),
                    pagethree: (
                      <form
                        id="createEmployeeForm_pagethree"
                        ref={formRef_three}
                        onSubmit={(e) => handleManualSubmit(e, "pagefour")}
                      >
                        <h6 className="heading mt-4">Upload Document</h6>
                        <UploadDocument />
                        <div className="action d-flex justify-content-between mt-5">
                          <button
                            className="theme-button bg-grey "
                            onClick={() => nextPage("pagetwo")}
                          >
                            Previous
                          </button>
                          <button
                            className="theme-button"
                            // onClick={() => nextPage("pagefour")}
                          >
                            Next
                          </button>
                        </div>
                      </form>
                    ),
                    pagefour: (
                      <form
                        id="createEmployeeForm_pagefour"
                        ref={formRef_four}
                        onSubmit={(e) => handleManualSubmit(e, "")}
                      >
                        {/* <h6 className="heading mt-2">Review</h6> */}
                        <ReviewEmployeeData empData={formData} />
                        <div className="action d-flex justify-content-between mt-5">
                          <button
                            className="theme-button bg-grey"
                            type="button"
                            onClick={() => nextPage("pagetwo")}
                          >
                            Previous
                          </button>
                          <button
                            className={
                              isLoading
                                ? "theme-button disabled "
                                : "theme-button"
                            }
                            type="submit"
                          >
                            {isLoading ? (
                              <ThreeDots
                                height="25"
                                width="80"
                                radius="9"
                                color="white"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                        {/* <div className="action text-center d-flex  justify-content-center  ">
                        <button
                          className={
                            isLoading
                              ? "theme-button me-3 disabled "
                              : "theme-button me-3 "
                          }
                          type="submit"
                        >
                          {isLoading ? (
                            <ThreeDots
                              height="25"
                              width="80"
                              radius="9"
                              color="white"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>

                        <a
                          className="theme-button bg-grey"
                          onClick={handleReset}
                        >
                          Reset
                        </a>
                      </div> */}
                      </form>
                    ),
                  }[page]
                }
              </>

              <div className="text-center">
                {isError && <div className="error mb-3 ">{error}</div>}
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
          <div
            className="modal fade"
            id="staticBackdropValidate"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <form onSubmit={handleSubmitValidate}>
                  <div className="modal-header">
                    <h5
                      className="modal-title text-center flex"
                      id="staticBackdropLabel"
                    >
                      Verify
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      // onClick={handleReset}
                      style={{ display: "none" }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="row mt-3 mb-3">
                      <div className="col-md-2"></div>
                      <div className="col-md-3">
                        <label htmlFor="personalEmail" className="has-asterisk">
                          Personal Email
                        </label>
                      </div>
                      <div className="col-md-5">
                        <div className="input-group">
                          <input
                            type="email"
                            name="personalEmail"
                            maxLength={100}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="ted@gmail.com"
                            className="border"
                            required
                          />
                          {errors.email && (
                            <small className="text-danger">
                              {errors.email}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                    <h6 className="text-center text-primary">
                      Note: Verify your personal email on which you received the
                      onboard form link.
                    </h6>
                  </div>

                  <div className="justify-content-center modal-footer">
                    <button
                      type="submit"
                      className={
                        isLoading ? "theme-button disabled " : "theme-button "
                      }
                    >
                      {isLoading ? (
                        <ThreeDots
                          height="20"
                          width="53"
                          radius="9"
                          color="white"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
                {/* <ValidateUser /> */}
              </div>
            </div>
          </div>
        </div>

        // </form>
      )}
      {isSubmitted && (
        <div className="row mt-5">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <div className="card text-center d-flex  justify-content-center ">
              <div className="m-2">
                <img
                  src="./assets/images/check.png"
                  alt="success"
                  width="150px"
                  height="150px"
                />
              </div>
              <h2 className="fw-300">Thank you!</h2>
              <p>
                Your submission has been sent successfully.
                <br /> HR will get back to you shortly.
              </p>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};
export default CreateEmployee;

const schema = Yup.object().shape({
  personalEmail: Yup.string()
    .email("Invalid email address")
    .required("Personal Email is required")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address"),
  dob: Yup.date()
    .test(
      "is-18-or-over",
      "You must be at least 18 years old.",
      function (value) {
        const today = new Date();
        const age = today.getFullYear() - value.getFullYear();
        return age >= 18;
      }
    )
    .required(),
  joiningDate: Yup.date()
    .required("Date of Joining is required")
    .min(
      new Date(new Date().setDate(new Date().getDate() - 30)),
      "Date of Joining should be within the past 30 days"
    ),
});
const schemaValidate = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Personal Email is required")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address"),
});
