import React, { useEffect, useState } from "react";
import SideNavbar from "../components/SideNavbar";
import Timesheets from "./AttendenceManagement/Timesheets";
import { Route, Routes, useNavigate } from "react-router-dom";
import CreateEmployee from "./EmployeeOnboard/CreateEmployee";
import Performance from "./Employee/Performance";
import LeaveList from "./Leave/LeaveList";
import AssetList from "./AssetManagement/AssetList";
import Profile from "./Auth/Profile";
import AssignAsset from "./AssetManagement/AssignAsset";
import UpdateEmployeeOnboarding from "./EmployeeOnboard/UpdateEmployeeOnboarding";
import AssetDeallocationList from "./AssetManagement/AssetDeallocationList";
import AssetDeallocation from "./AssetManagement/AssetDeallocation";
import CreateAsset from "./AssetManagement/CreateAsset";
import EmployeesOnboarding from "./EmployeeOnboard/EmployeesOnboarding";
import AdminProfile from "./Employee/AdminProfile";
import EmployeeProfile from "./EmployeeOnboard/EmployeeProfile";
import MainDashboard from "./MainDashboard";
import AssetDashboard from "./AssetManagement/AssetDashboard";
import PromoteEmployee from "./PromoteEmployee/PromoteEmployee";
import ProjectList from "./Project/ProjectList";
import CreateProject from "./Project/CreateProject";
import EmployeeList from "./Employee/EmployeeList";
import AssignManagerEmployee from "./AssignManager/AssignManagerEmployee";
import TimeSheetView from "./AttendenceManagement/TimeSheetView";
import ApproveLeave from "./Leave/ApproveLeave";
import ApplyLeave from "./Leave/ApplyLeave";
import AssetRequest from "./AssetManagement/AssetRequest";
import Cookies from "js-cookie";
import { TailSpin } from "react-loader-spinner";
import { getApi } from "../utils/api";
import { MENU, MENU_USER } from "../config/Endpoints";
import { toast } from "react-toastify";
import { logoutUser } from "../utils/Storage";
import UploadSalary from "./Employee/UploadSalary";
import Reimbursement from "./ExpenseManagement/Reimbursement";
import AssetRequestList from "./AssetManagement/AssetRequestList";
import ApproveAssetRequestList from "./AssetManagement/ApproveAssetRequestList";
import ApproveReimbursementList from "./ExpenseManagement/ApproveReimbursementList";
import ReimbursementRequestList from "./ExpenseManagement/ReimbursementRequestList";
import ModifyPolicy from "./PolicyManagement/ModifyPolicy";
import ViewPolicy from "./PolicyManagement/ViewPolicy";
import NormalPolicy from "./PolicyManagement/NormalPolicy";
import UploadDocument from "./Employee/UploadDocument";
import LeaveUpdate from "./Leave/LeaveUpdate";
import FinalApproveLeave from "./Leave/FinalApproveLeave";
import DownloadReimbursement from "./ExpenseManagement/DownloadReimbursement";
import RoleList from "./Role/RoleList";
import MenuAssign from "./Menu/MenuAssign";
import PunchDetail from "./AttendenceManagement/PunchDetail";
import BulkPaid from "./ExpenseManagement/BulkPaid";
import EvaluateEmployee from "./Employee/EvaluateEmployee";
import EvaluationView from "./Employee/EvaluationView";
import RoleAssignmentToEmp from "./Employee/RoleAssignmentToEmp";
import AppraisalUpload from "./AppraisalManagement/AppraisalUpload";
import ApproveDocument from "./Employee/ApproveDocument";
import SendEmployeeOnboarding from "./EmployeeOnboard/SendEmployeeOnboarding";
import InvitationList from "./EmployeeOnboard/InvitationList";
import RaiseEmailRequest from "./RaiseEmailRequest/RaiseEmailRequest";
import EmailCreationRequestApproval from "./RaiseEmailRequest/EmailCreationRequestApproval";
import ApproveAssetRequestL1 from "./AssetLevelManagement/ApproveAssetRequestL1";
import ApproveAssetRequestL2 from "./AssetLevelManagement/ApproveAssetRequestL2";
import ApproveAssetRequestIT from "./AssetLevelManagement/ApproveAssetRequestIT";
import AssetDeliveryStore from "./AssetLevelManagement/AssetDeliveryStore";
import AssetRequestITApproverForm from "./AssetLevelManagement/AssetRequestITApproverForm";
import UsersRoleReport from "./ManagementReports/UsersRoleReport";
import AssetReport from "./ManagementReports/AssetReport";
import AssetReturnATO from "./AssetManagement/AssetReturnATO";
import AssetReturnedList from "./AssetManagement/AssetReturnedList";
import CreateAnnouncement from "./AnnouncementManagement/CreateAnnouncement";
import ViewAnnouncement from "./AnnouncementManagement/ViewAnnouncement";
import DepartmentList from "./Settings/DepartmentList";
import Designationlist from "./Settings/Designationlist";
import Biometric from "./Employee/Biometric/EmployeeBiometricList";
import BiometricList from "./Employee/Biometric/EmployeeBiometricList";
import BiometricEmployeeProfile from "./Employee/Biometric/BiometricEmployeeProfile";
import EmployeeBiometricList from "./Employee/Biometric/EmployeeBiometricList";
import MyAttendance from "./AttendenceManagement/MyAttendance";
import ExportAttendance from "./AttendenceManagement/ExportAttendance";
import AssignMain from "./AssignManager/AssignMain";
import LeaveCreditRequest from "./AttendenceManagement/LeaveCreditRequest";
import ApproveLeaveCreditRequest from "./AttendenceManagement/ApproveLeaveCreditRequest";
import ReimbursementHistory from "./ExpenseManagement/ReimbursementHistory";
import OnLeaveToday from "./Leave/OnLeaveToday";
import HolidayList from "./AttendenceManagement/HolidayList";
import HolidayManagement from "./AttendenceManagement/HolidayManagement";
import ApplyRegularization from "./AttendenceManagement/ApplyRegularization";
import ApproveRegularization from "./AttendenceManagement/ApproveRegularization";
import TeamRegularizations from "./AttendenceManagement/TeamRegularizations";

function Home() {
  const [menuList, setMenuList] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const accessToken = Cookies.get("accessToken");
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getApi(MENU_USER, accessToken)
      .then((res) => {
        if (res.data.code === "200") {
          setMenuList(res.data.data);
          setIsLoading(false);
        } else if (res.data.code === "500") {
          toast.error("Something went wrong please try again !!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
        } else {
          navigate("/");
          setIsLoading(false);
        }
      })
      .catch((e) => {
        toast.error("Something went wrong please try again !!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        logoutUser();
        console.log(e);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <div className="wrapper">
        {isLoading && (
          <div className="overlay">
            <div className="loader-container">
              <TailSpin
                height="100"
                width="100"
                radius="1"
                color="blue"
                ariaLabel="tail-spin-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          </div>
        )}
        {!isLoading && isLoading !== null && (
          <>
            <SideNavbar menu={menuList} />
            <Routes>
              <Route exact path="/dashboard/">
                <Route exact index={true} element={<MainDashboard />} />
                <Route path="employee-list" element={<EmployeeList />} />
                <Route path="promoteEmployee" element={<PromoteEmployee />} />
                <Route path="approveDocument" element={<ApproveDocument />} />
                <Route path="punchDetail" element={<PunchDetail />} />
                <Route path="uploadDocument" element={<UploadDocument />} />
                <Route path="assignManager" element={<AssignMain />} />
                <Route
                  path="updateOnboarding"
                  element={<UpdateEmployeeOnboarding />}
                />
                <Route
                  path="employeeOverview"
                  element={<EmployeesOnboarding />}
                />
                {/* New Onboarding Flow */}
                <Route path="inviteOnboarding" element={<InvitationList />} />
                <Route
                  path="l1OnboardingApproval"
                  element={<UpdateEmployeeOnboarding />}
                />
                <Route
                  path="hrOnboardingApproval"
                  element={<EmployeesOnboarding />}
                />
                {/* End of New Onboarding Flow */}
                {/* <Route
                  path="sendOnboarding"
                  element={<SendEmployeeOnboarding />}
                /> */}
                <Route path="create-employee" element={<CreateEmployee />} />
                <Route path="profile" element={<Profile />} />
                <Route path="leaveApply" element={<LeaveList />} />
                <Route path="apply-leave" element={<ApplyLeave />} />
                <Route path="approveLeave" element={<ApproveLeave />} />
                <Route
                  path="finalAproverLeave"
                  element={<FinalApproveLeave />}
                />
                <Route path="leave-list" element={<LeaveUpdate />} />
                <Route path="myAttendance" element={<MyAttendance />} />
                <Route
                  path="applyRegularization"
                  element={<ApplyRegularization />}
                />
                <Route
                  path="approve-regularization"
                  element={<ApproveRegularization />}
                />
                <Route
                  path="my-team-regularization"
                  element={<TeamRegularizations />}
                />
                <Route path="exportAttendance" element={<ExportAttendance />} />
                <Route
                  path="createLeaveCreditRequest"
                  element={<LeaveCreditRequest />}
                />
                <Route path="on-leave-today" element={<OnLeaveToday />} />
                <Route path="holiday-list" element={<HolidayList />} />
                <Route
                  path="holiday-management"
                  element={<HolidayManagement />}
                />
                <Route
                  path="approveLeaveCreditRequest"
                  element={<ApproveLeaveCreditRequest />}
                />

                <Route path="performance" element={<Performance />} />
                <Route path="evaluateEmployee" element={<EvaluateEmployee />} />
                <Route path="evaluationView" element={<EvaluationView />} />

                {/* Asset Deallocation Route */}
                <Route path="assign-asset" element={<AssignAsset />} />
                <Route path="assetDashboard" element={<AssetDashboard />} />
                <Route path="create-asset" element={<CreateAsset />} />
                <Route path="assetAllocation" element={<AssetList />} />
                <Route
                  path="assetDeallocation"
                  element={<AssetDeallocation />}
                />
                <Route path="assetRequest" element={<AssetRequestList />} />
                <Route
                  path="approveAssestRequest"
                  element={<ApproveAssetRequestList />}
                />

                <Route path="createAssetRequest" element={<AssetRequest />} />
                <Route path="myProfile" element={<AdminProfile />} />
                <Route path="employeeProfile" element={<EmployeeProfile />} />
                <Route path="timeSheet" element={<Timesheets />} />
                <Route path="timeSheetView" element={<TimeSheetView />} />
                <Route path="projectList" element={<ProjectList />} />
                <Route path="createProject" element={<CreateProject />} />
                <Route path="uploadSalary" element={<UploadSalary />} />
                <Route path="uploadAppraisal" element={<AppraisalUpload />} />

                <Route
                  path="approveReimbursement"
                  element={<ApproveReimbursementList />}
                />
                <Route
                  path="reimbursement"
                  element={<ReimbursementRequestList />}
                />
                <Route
                  path="reimbursement-history"
                  element={<ReimbursementHistory />}
                />
                <Route
                  path="createReimbursementRequest"
                  element={<Reimbursement />}
                />
                <Route
                  path="downloadReimbursement"
                  element={<DownloadReimbursement />}
                />
                <Route path="bulkPaid" element={<BulkPaid />} />
                <Route path="modifyPolicy" element={<ModifyPolicy />} />
                <Route path="viewPolicy" element={<ViewPolicy />} />
                <Route path="normalPolicy" element={<NormalPolicy />} />

                <Route path="roleList" element={<RoleList />} />
                <Route path="menuAssign" element={<MenuAssign />} />
                <Route
                  path="roleAssignment"
                  element={<RoleAssignmentToEmp />}
                />
                <Route
                  path="emailCreationRequest"
                  element={<RaiseEmailRequest />}
                />
                <Route
                  path="emailCreationRequestApproval"
                  element={<EmailCreationRequestApproval />}
                />

                {/*--------- New Routes for asset ---------*/}
                <Route
                  path="approve-asset-request-l1"
                  element={<ApproveAssetRequestL1 />}
                />
                <Route
                  path="approve-asset-request-l2"
                  element={<ApproveAssetRequestL2 />}
                />
                <Route
                  path="approve-asset-request-it"
                  element={<ApproveAssetRequestIT />}
                />
                <Route path="deliver-asset" element={<AssetDeliveryStore />} />
                <Route
                  path="approve-asset-IT"
                  element={<AssetRequestITApproverForm />}
                />
                {/*--------- New Routes for asset ---------*/}
                <Route path="user-role-report" element={<UsersRoleReport />} />
                <Route path="asset-report" element={<AssetReport />} />
                <Route path="rto-asset" element={<AssetReturnATO />} />
                <Route path="returned-asset" element={<AssetReturnedList />} />
                <Route
                  path="create-announcement"
                  element={<CreateAnnouncement />}
                />
                <Route
                  path="view-announcement"
                  element={<ViewAnnouncement />}
                />
                <Route path="department" element={<DepartmentList />} />
                <Route path="designation" element={<Designationlist />} />
                {/*  */}
                <Route
                  path="biometirc-mapping"
                  element={<EmployeeBiometricList />}
                />
                <Route
                  path="biometric-employee-profile"
                  element={<BiometricEmployeeProfile />}
                />
              </Route>
            </Routes>
          </>
        )}
      </div>
    </>
  );
}

export default Home;
