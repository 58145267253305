import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { getApi } from "../../utils/api";
import { API_APPROVE_REIMBURSEMENT_LIST } from "../../config/Endpoints";
import { ThreeDots } from "react-loader-spinner";
import ApproveReimbursementRequestForm from "./ApproveReimbursementRequestForm";
import { toggleMenu } from "../../utils/helpers";
import Pagination from "../../components/Pagination";
import Footer from "../../components/Footer";

const ApproveReimbursementList = () => {
  const [reimbursementId, setReimbursementId] = useState("");
  const [reimbursementList, setReimbursementList] = useState([]);
  const [requestReimbursement, setRequestReimbursement] = useState([]);
  const [reimbursementStatusList, setReimbursementStatusList] = useState([]);
  const [selectedReimbursementRequest, setSelectedReimbursementRequest] =
    useState({});
  const [filteredItems, setFilteredItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [searchTerm, setSearchTerm] = useState("");

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalReimbursement, setTotalReimbursement] = useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "",
  });

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, limit]);

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  const fetchList = () => {
    setIsLoading(true);
    getApi(
      API_APPROVE_REIMBURSEMENT_LIST + `?pageSize=${limit}&page=${currentPage}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setReimbursementList(res.data.data);
          setFilteredItems(res.data.data);
          setRequestReimbursement(res.data);
          setReimbursementStatusList(res.data.status);
          setTotalReimbursement(res.data.totalRequest);
          setTotalPages(res.data.totalPages);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = reimbursementList.filter((item) =>
      item.employeeName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredItems].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredItems(sortedData);
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1> Approve Reimbursement</h1>
        <div className="total">
          Total Reimbursement : <span>{totalReimbursement}</span>
        </div>
      </div>
      {/* Filters */}
      <div className="filters">
        {/* <form action="index.html" id="form_filters" className="d-f"> */}
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Employee Name"
            value={searchTerm}
            onChange={handleSearch}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>

        {/* <button type="submit" form="form_filters">
          Search
        </button> */}
        {/* </form> */}

        {/* Actions */}
        <div className="actions">
          {/* <Link
            to="/dashboard/createAssetRequest"
            className="export theme-button"
          >
            Asset Request
          </Link> */}
        </div>
      </div>
      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      {/* Data Table */}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th onClick={() => handleSort("reimbursementViewId")}>
                    Reimbursement ID
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("employeeName")}>
                    Employee Name
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("reimbursementType")}>
                    Reimbursement Type
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("reimbursementAmount")}>
                    Amount
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("requestDate")}>
                    Requested Date
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("trackingStatus")}>
                    Status
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th>Action</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems &&
                  filteredItems?.map((item) => (
                    <tr key={item.reimbursementId}>
                      <td data-title="reimbursementViewId">
                        {item.reimbursementViewId}
                      </td>
                      <td data-title="employeeName">{item.employeeName}</td>
                      <td data-title="reimbursementType">
                        {item.reimbursementType}
                      </td>
                      <td data-title="reimbursementAmount ">
                        {item.reimbursementAmount}.00
                      </td>
                      <td data-title="requestDate">{item.requestDate}</td>
                      <td data-title="trackingStatus">{item.trackingStatus}</td>
                      <td data-title="serialNumber">
                        {requestReimbursement?.approver ? (
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdropAssetRequest"
                            onClick={() => {
                              setReimbursementId(item.reimbursementId);
                              setSelectedReimbursementRequest(item);
                            }}
                          >
                            View
                          </a>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div>
                {/* Dropdown for selecting items per page */}
                <label htmlFor="itemsPerPage">Items per page </label>
                <select
                  id="itemsPerPage"
                  onChange={handleLimitChange}
                  value={limit}
                  className="border ms-3 w-25  p-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                </select>
              </div>
            </div>
            <div className="col-md-9">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}
      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center">
          <h4>No Record Available</h4>
        </div>
      )}{" "}
      <div
        className="modal fade"
        id="staticBackdropAssetRequest"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <ApproveReimbursementRequestForm
              reimbursementId={reimbursementId}
              selectedReimbursementRequest={selectedReimbursementRequest}
              reimbursementStatusList={reimbursementStatusList}
              fetchList={fetchList}
            />
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ApproveReimbursementList;
