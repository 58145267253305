import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import {
  API_POLICY,
  API_ROLE,
  GET_ONBOARDING_EMPLOYEE_LIST,
} from "../../config/Endpoints";
import { postApi, putApi } from "../../utils/api";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import * as Yup from "yup";

const CreateRoleModal = ({ selectedRole, setSelectedRole, fetchList }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [errors, setErrors] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setFormData({
      title: "",
      description: "",
    });
    setSelectedRole({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let objRequest = {
        name: formData.title,
        description: formData.description,
      };
      if (selectedRole?.roleId) {
        await putApi(
          API_ROLE + "/" + selectedRole?.roleId,
          objRequest,
          accessToken
        )
          .then((res) => {
            setIsLoading(false);
            toast.success("Successfully updated.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: true,
            });
            handleReset();
            fetchList();
            const modalClose =
              document.getElementsByClassName("cancel-modal")[0];
            modalClose.click();
          })
          .catch((e) => {
            console.log(e);
            setIsLoading(false);
            toast.error("Something went wrong please try again!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      } else {
        await postApi(API_ROLE, objRequest, accessToken)
          .then((res) => {
            setIsLoading(false);
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: true,
            });
            handleReset();
            fetchList();
            const modalClose =
              document.getElementsByClassName("cancel-modal")[0];
            modalClose.click();
          })
          .catch((e) => {
            console.log(e);
            setIsLoading(false);
            toast.error(e?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setIsLoading(false);

      setErrors(validationErrors);
    }
  };

  useEffect(() => {
    if (Object.keys(selectedRole).length > 0) {
      setFormData({
        title: selectedRole?.name,
        description: selectedRole?.description,
      });
    }
  }, [selectedRole]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-header">
        <h5 className="modal-title text-center flex" id="staticBackdropLabel">
          {Object.keys(selectedRole).length > 0 ? "Update" : "Add"} Role
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleReset}
        ></button>
      </div>
      <div className="modal-body">
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="title" className="has-asterisk">
              Role Title{" "}
            </label>
          </div>
          <div className="col-md-4 ">
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              placeholder="Enter Role Title"
              className="border"
              maxLength={20}
              required
              onKeyPress={(e) => {
                const inputValue = e.key;
                // Prevent adding space at the beginning or first character as number
                if (
                  (e.charCode === 32 && e.target.selectionStart === 0) || // Prevent space at the beginning
                  (e.target.selectionStart === 0 && /^\d$/.test(inputValue)) // Prevent first character as number
                ) {
                  e.preventDefault();
                }
              }}
              onKeyUp={(e) => {
                const inputValue = e.target.value;
                // Remove numbers if the input field only contains numbers
                if (/^\d+$/.test(inputValue)) {
                  e.target.value = "";
                }
              }}
              // onKeyPress={(e) => {
              //   const inputValue = e.key;
              //   // Prevent adding space at the beginning or first character as number
              //   if (
              //     (e.charCode === 32 && e.target.selectionStart === 0) || // Prevent space at the beginning
              //     (e.target.selectionStart === 0 && /^\d$/.test(inputValue)) // Prevent first character as number
              //   ) {
              //     e.preventDefault();
              //   }
              // }}
            />
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="description" className="has-asterisk">
              Brief Description About Role
            </label>
          </div>
          <div className="col-md-4 ">
            <textarea
              id="description"
              name="description"
              required
              value={formData.description}
              onChange={handleInputChange}
              rows={3}
              cols={20}
              className="border"
              maxLength={160}
              onKeyPress={(e) => {
                // Prevent adding space at the beginning
                if (e.charCode === 32 && e.target.selectionStart === 0) {
                  e.preventDefault();
                }

                // Regular expression to allow only letters, numbers, and spaces
                const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                // Check if the character is allowed
                const char = String.fromCharCode(e.charCode);
                if (!allowedCharsRegex.test(char)) {
                  e.preventDefault(); // Prevent non-alphanumeric character input
                }
              }}
            ></textarea>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>

      <div className="justify-content-center modal-footer">
        <button
          type="submit"
          className={isLoading ? "theme-button disabled " : "theme-button "}
        >
          {isLoading ? (
            <ThreeDots
              height="20"
              width="53"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            "Submit"
          )}
        </button>
        <button
          type="button"
          className="theme-button bg-grey mx-3 w-35 cancel-modal"
          data-bs-dismiss="modal"
          onClick={handleReset}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default CreateRoleModal;
