import React, { useEffect, useState } from "react";
import { toggleMenu } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { ThreeDots } from "react-loader-spinner";
import Footer from "../../components/Footer";
import {
  API_CREATE_EMAIL_REQUEST,
  API_GET_EMAIL_REQUEST,
} from "../../config/Endpoints";
import { getApi, postApi } from "../../utils/api";
import Pagination from "../../components/Pagination";
import * as Yup from "yup";
import { toast } from "react-toastify";
import moment from "moment";
import * as XLSX from "xlsx";

const RaiseEmailRequest = () => {
  const [emailRequestList, setEmailRequestList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("pending");
  const [filteredItems, setFilteredItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    purposedEmail: "",
    firstName: "",
    aliasName: "",
    phoneNumber: "",
    personalEmail: "",
    comment: "",
  });

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalEmailRequest, setTotalEmailRequest] = useState(0);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, limit, selectedStatus]);

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  const fetchList = () => {
    setIsLoading(true);
    getApi(
      API_GET_EMAIL_REQUEST +
        `?pageSize=${limit}&page=${currentPage}&status=${selectedStatus}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          setEmailRequestList(res.data.data);
          setFilteredItems(res.data.data);
          setTotalEmailRequest(res.data.totalRequest);
          setTotalPages(res.data.totalPages);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleInputChange = (event) => {
    const { name } = event.target;
    setErrors("");
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setFormData({
      purposedEmail: "",

      firstName: "",
      aliasName: "",
      phoneNumber: "",
      personalEmail: "",
      comment: "",
    });
    setErrors("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsButtonLoading(true);
    try {
      let { personalEmail, purposedEmail } = formData;
      await schema.validate(
        { personalEmail, purposedEmail },
        {
          abortEarly: false,
        }
      );
      setErrors("");
      // Check if email contains '@companyname'
      if (!purposedEmail.endsWith("@caeliusconsulting.com")) {
        setIsButtonLoading(false);
        toast.error(
          "Please make sure to use a company email address for the purposed email, and it should ends with @caeliusconsulting.com ",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        return;
      }

      let objRequest = {
        purposedEmail: formData.purposedEmail,
        firstName: formData.firstName,
        aliasName: formData.aliasName,
        phoneNumber: formData.phoneNumber,
        personalEmail: formData.personalEmail,
        comment: formData.comment,
      };
      await postApi(API_CREATE_EMAIL_REQUEST, objRequest, accessToken)
        .then((res) => {
          setIsButtonLoading(false);
          // toast.success(res.data.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose:{5000},
          // closeOnClick: true,
          // autoClose: false,
          // });
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          fetchList();
          handleReset();
          const modalClose = document.getElementsByClassName("cancel-modal")[0];
          modalClose.click();
        })
        .catch((e) => {
          setIsButtonLoading(false);
          toast.error(
            e?.response?.data?.message
              ? e.response.data.message
              : "Something went wrong please try again!",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setIsButtonLoading(false);

      setErrors(validationErrors);
    }
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = emailRequestList.filter((item) =>
      item.firstName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.table_to_sheet(
      document.getElementById("table-email-request")
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${"Email_request_" + selectedStatus}.xlsx`);
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>Email Creation Requests</h1>
        <div className="total">
          Total Requests : <span>{filteredItems?.length}</span>
        </div>
      </div>

      {/* Filters */}
      <div className="filters">
        {/* <form action="index.html" id="form_filters" className="d-f"> */}
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Name."
            value={searchTerm}
            onChange={handleSearch}
            onKeyPress={(e) => {
              if (e.charCode === 32 && e.target.selectionStart === 0) {
                e.preventDefault(); // Prevent adding space at the beginning
              }
            }}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>

        <div className="search">
          <select
            id="status"
            required
            className="form-select"
            name="status"
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
              setSearchTerm("");
            }}
          >
            {/* <option value="" disabled>
              Select Status
            </option> */}
            <option value="pending">Pending</option>
            <option value="Approved">Approved</option>
            <option value="Rejected">Rejected</option>
          </select>
        </div>
        <div className="actions">
          <button
            className="export theme-button bg-success"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropRequest"
            // onClick={handleFilter}
          >
            + Add Request
          </button>
        </div>
        <div className="actions ms-2">
          <button
            className={
              "export theme-button  " +
              (filteredItems?.length === 0 || emailRequestList?.length === 0
                ? " disabled"
                : "")
            }
            onClick={exportToExcel}
            disabled={
              filteredItems?.length === 0 || emailRequestList?.length === 0
            }
          >
            Export to Excel
          </button>
        </div>
        {/* <button type="submit" form="form_filters">  
        Search
      </button> */}
        {/* </form> */}

        {/* Actions */}
        <div className="actions"></div>
      </div>
      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}

      {/* Data Table */}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom" id="table-email-request">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Name</th>
                  <th>Purposed Email</th>
                  <th>Personal Email</th>
                  <th>Phone Number</th>
                  <th>Request By</th>
                  <th>Requested Date</th>
                  <th>comment</th>
                  <th>Status</th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems &&
                  filteredItems?.map((item, index) => (
                    <tr key={index}>
                      <td data-title="Serialno">{index + 1}</td>
                      <td data-title="firstName">{item.firstName}</td>
                      <td data-title="purposedEmail">{item.purposedEmail}</td>
                      <td data-title="purposedEmail">{item.personalEmail}</td>
                      <td data-title="phoneNumber">{item.phoneNumber}</td>
                      <td data-title="requestByName">{item.requestByName}</td>
                      <td data-title="requestDateTime">
                        {moment(item.requestDateTime).format("MM/DD/YYYY")}
                      </td>
                      <td data-title="comment">{item.comment}</td>
                      <td data-title="status">
                        <small
                          className={`text-capitalize badge bg-${
                            item.status === "pending"
                              ? "warning"
                              : item.status === "Rejected"
                              ? "danger"
                              : "success"
                          }`}
                        >
                          {item.status}
                        </small>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div>
                {/* Dropdown for selecting items per page */}
                <label htmlFor="itemsPerPage">Items per page </label>
                <select
                  id="itemsPerPage"
                  onChange={handleLimitChange}
                  value={limit}
                  className="border ms-3 w-25  p-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                </select>
              </div>
            </div>
            <div className="col-md-9">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}
      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center mt-5">
          <h4>No Record Available</h4>
        </div>
      )}

      <div
        className="modal fade"
        id="staticBackdropRequest"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5
                  className="modal-title text-center flex"
                  id="staticBackdropLabel"
                >
                  Add Email Request
                </h5>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleReset}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label className="has-asterisk">Purposed Email</label>
                  </div>

                  <div className="col-md-4">
                    <input
                      type="email"
                      name="purposedEmail"
                      value={formData.purposedEmail}
                      onChange={handleInputChange}
                      placeholder="Enter purposed email"
                      maxLength={100}
                      required
                      className="border"
                    />
                    {errors.purposedEmail && (
                      <div className="error text-danger">
                        {errors.purposedEmail}
                      </div>
                    )}
                  </div>
                  <div className="col-md-2"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label className="has-asterisk">Suggested Name</label>
                  </div>

                  <div className="col-md-4">
                    <input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      maxLength={30}
                      onChange={handleInputChange}
                      placeholder="Enter suggested name"
                      required
                      className="border"
                      onKeyPress={(e) => {
                        const inputValue = String.fromCharCode(e.charCode);

                        // Check if the character is a space at the beginning
                        if (
                          inputValue === " " &&
                          e.target.selectionStart === 0
                        ) {
                          e.preventDefault(); // Prevent adding space at the beginning
                        }

                        // Check if the character is an alphabetic character or space
                        if (
                          /^[a-zA-Z ]$/.test(inputValue) // Alphabets or space
                        ) {
                          // Allow the character to be typed
                        } else {
                          e.preventDefault(); // Prevent adding the character
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-2"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="manager" className="has-asterisk">
                      Personal Email
                    </label>
                    <br />
                    <small className="text-warning pt-0">
                      (Credentials will be sent to this email.)
                    </small>
                  </div>
                  <div className="col-md-4 ">
                    <input
                      type="email"
                      maxLength={100}
                      name="personalEmail"
                      value={formData.personalEmail}
                      onChange={handleInputChange}
                      placeholder="Enter personal email"
                      className="border"
                      required
                    />

                    {errors.personalEmail && (
                      <div className="error text-danger">
                        {errors.personalEmail}
                      </div>
                    )}
                  </div>
                  <div className="col-md-2"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="manager" className="has-asterisk">
                      Phone Number
                    </label>
                  </div>
                  <div className="col-md-4 ">
                    <input
                      type="tel"
                      required
                      maxLength="10"
                      minLength="10"
                      name="phoneNumber"
                      className="border"
                      value={formData.phoneNumber}
                      onChange={handleInputChange}
                      placeholder="9876543210"
                      onKeyPress={(e) => {
                        const inputValue = e.key;
                        const value = e.target.value + inputValue;
                        // Check for continuous repeating digits or non-numeric characters
                        if (
                          /(\d)\1{9}/.test(value) ||
                          !/^[0-9\b]+$/.test(inputValue)
                        ) {
                          e.preventDefault(); // Prevent adding continuous repeating digits or non-numeric characters
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                  <div className="col-md-2"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="manager">Comment</label>
                  </div>
                  <div className="col-md-4 ">
                    <textarea
                      rows={3}
                      name="comment"
                      className="border"
                      value={formData.comment}
                      onChange={handleInputChange}
                      maxLength={160}
                      onKeyPress={(e) => {
                        // Prevent adding space at the beginning
                        if (
                          e.charCode === 32 &&
                          e.target.selectionStart === 0
                        ) {
                          e.preventDefault();
                        }

                        // Regular expression to allow only letters, numbers, and spaces
                        const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                        // Check if the character is allowed
                        const char = String.fromCharCode(e.charCode);
                        if (!allowedCharsRegex.test(char)) {
                          e.preventDefault(); // Prevent non-alphanumeric character input
                        }
                      }}
                    ></textarea>
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>

              <div className="justify-content-center modal-footer">
                <button
                  type="submit"
                  className={
                    isButtonLoading ? "theme-button disabled " : "theme-button "
                  }
                >
                  {isButtonLoading ? (
                    <ThreeDots
                      height="20"
                      width="53"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  type="button"
                  className="theme-button bg-grey mx-3 w-35 cancel-modal"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default RaiseEmailRequest;

const schema = Yup.object().shape({
  personalEmail: Yup.string()
    .email("Invalid email address")
    .required("Personal Email is required")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email address"
    )
    .test(
      "not-same-as-personal",
      "Personal Email cannot be the same as Purposed Email",
      function (value) {
        return this.parent.purposedEmail !== value;
      }
    ),
  purposedEmail: Yup.string()
    .email("Invalid email address")
    .required("Purposed Email is required")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email address"
    ),
});
