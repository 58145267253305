import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { API_REGULARIZATION } from "../../config/Endpoints";
import Cookies from "js-cookie";
import { postApi } from "../../utils/api";
import { toast } from "react-toastify";

const ApplyRegularizationModal = ({ fetchList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    attendanceDateFor: "",
    regDir: "",
    regHour: "",
    reason: "",
  });

  const accessToken = Cookies.get("accessToken");

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === "date") {
      const year = value.split("-")[0];
      if (year.length <= 4) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleReset = () => {
    setFormData({
      attendanceDateFor: "",
      regDir: "",
      regHour: "",
      reason: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await postApi(API_REGULARIZATION, formData, accessToken)
        .then((res) => {
          setIsLoading(false);
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchList();
          const modalClose =
            document.getElementsByClassName("cancel-modal-apply")[0];
          modalClose.click();
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const today = new Date();
  const lastMonth = new Date();
  lastMonth.setMonth(today.getMonth() - 1);

  // Format dates to YYYY-MM-DD for the input value
  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  const minDate = formatDate(lastMonth);
  const maxDate = formatDate(today);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h5 className="modal-title text-center flex" id="staticBackdropLabel">
            Apply Regularization
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleReset}
          ></button>
        </div>
        <div className="modal-body">
          <div className="row ">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <label htmlFor="leave_type_list" className="has-asterisk">
                Attendance Date
              </label>
            </div>
            <div className="col-md-4 ">
              <input
                type="date"
                required
                name="attendanceDateFor"
                className="border"
                value={formData.attendanceDateFor}
                placeholder="08/02/2019"
                onChange={handleInputChange}
                min={minDate}
                max={maxDate}
              />
            </div>
            <div className="col-md-2"></div>
          </div>

          <div className="row mt-2">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <label htmlFor="leave_type_list" className="has-asterisk">
                Punch IN/Out
              </label>
            </div>
            <div className="col-md-4 ">
              <select
                id="regDir"
                name="regDir"
                className="border"
                value={formData.regDir}
                onChange={handleInputChange}
                required
              >
                <option value="">Select </option>
                <option value="IN">IN</option>
                <option value="OUT">OUT</option>
              </select>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row mt-2">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <label htmlFor="leave_type_list" className="has-asterisk">
                Regularization Time
              </label>
            </div>
            <div className="col-md-4 ">
              <input
                type="time"
                required
                name="regHour"
                className="border"
                value={formData.regHour}
                placeholder="hh:mm"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row mt-2">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <label htmlFor="reason" className="has-asterisk">
                Reason
              </label>
            </div>
            <div className="col-md-4">
              <textarea
                id="reason"
                name="reason"
                required
                value={formData.reason}
                onChange={handleInputChange}
                rows={2}
                cols={30}
                className="border"
                maxLength={100}
                minLength={5}
                onKeyPress={(e) => {
                  // Prevent adding space at the beginning
                  if (e.charCode === 32 && e.target.selectionStart === 0) {
                    e.preventDefault();
                  }

                  // Regular expression to allow only letters, numbers, and spaces
                  const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                  // Check if the character is allowed
                  const char = String.fromCharCode(e.charCode);
                  if (!allowedCharsRegex.test(char)) {
                    e.preventDefault(); // Prevent non-alphanumeric character input
                  }
                }}
              ></textarea>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>

        <div className="justify-content-center modal-footer">
          <button
            type="submit"
            className={isLoading ? "theme-button disabled " : "theme-button "}
          >
            {isLoading ? (
              <ThreeDots
                height="20"
                width="53"
                radius="9"
                color="white"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              "Submit"
            )}
          </button>
          <button
            type="button"
            className="theme-button bg-grey mx-3 w-35 cancel-modal-apply"
            data-bs-dismiss="modal"
            onClick={handleReset}
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  );
};

export default ApplyRegularizationModal;
