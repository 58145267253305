import React, { useEffect, useState } from "react";
import { toggleMenu } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { ThreeDots } from "react-loader-spinner";
import Footer from "../../components/Footer";
import {
  API_GET_EMAIL_REQUEST,
  API_UPDATE_EMAIL_REQUEST,
} from "../../config/Endpoints";
import { getApi, postApi, putApi } from "../../utils/api";
import Pagination from "../../components/Pagination";
import * as Yup from "yup";
import { toast } from "react-toastify";
import moment from "moment";
import * as XLSX from "xlsx";

const EmailCreationRequestApproval = () => {
  const [emailRequestList, setEmailRequestList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("pending");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const [emailRequestSelected, setEmailRequestSelected] = useState({});

  const accessToken = Cookies.get("accessToken");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    ccEmail: "",
    password: "",
    comment: "",
    status: "Approved",
  });

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalEmailRequest, setTotalEmailRequest] = useState(0);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, limit, selectedStatus]);

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  const fetchList = () => {
    setIsLoading(true);
    getApi(
      API_GET_EMAIL_REQUEST +
        `?pageSize=${limit}&page=${currentPage}&status=${selectedStatus}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          setEmailRequestList(res.data.data);
          setFilteredItems(res.data.data);
          setTotalEmailRequest(res.data.totalRequest);
          setTotalPages(res.data.totalPages);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = emailRequestList.filter((item) =>
      item.firstName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleInputChange = (event) => {
    const { name } = event.target;
    setErrors("");
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setFormData({
      ccEmail: "",
      password: "",
      comment: "",
      status: "Approved",
    });
    setErrors("");
    setEmailRequestSelected({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsButtonLoading(true);
    try {
      if (formData.status === "Approved") {
        let { ccEmail, password } = formData;
        await schema.validate(
          { ccEmail, password },
          {
            abortEarly: false,
          }
        );
        setErrors("");
        // Check if email contains '@companyname'
        if (!ccEmail.endsWith("@caeliusconsulting.com")) {
          setIsButtonLoading(false);
          toast.error(
            "Please make sure to use a company email address for the CC email, and it should ends with @caeliusconsulting.com ",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          return;
        }
      }

      let objRequest = {
        id: emailRequestSelected?.id,
        ccEmail: formData.ccEmail,
        password: formData.password,
        comment: formData.comment,
        status: formData.status,
      };
      await putApi(
        API_UPDATE_EMAIL_REQUEST + `?requestId=${emailRequestSelected?.id}`,
        objRequest,
        accessToken
      )
        .then((res) => {
          setIsButtonLoading(false);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          fetchList();
          handleReset();
          const modalClose = document.getElementsByClassName("cancel-modal")[0];
          modalClose.click();
        })
        .catch((e) => {
          setIsButtonLoading(false);
          toast.error(
            e?.response?.data?.message
              ? e.response.data.message
              : "Something went wrong please try again!",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setIsButtonLoading(false);
      setErrors(validationErrors);
    }
  };

  const handleSelectEmailRequest = (requestedData) => {
    setEmailRequestSelected(requestedData);
    const modalClose = document.getElementsByClassName("open-modal-email")[0];
    modalClose.click();
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.table_to_sheet(
      document.getElementById("table-approval-email-request")
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `${"Approval_Email_Request_" + selectedStatus}.xlsx`
    );
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>Approve Email Requests</h1>
        <div className="total">
          Total Requests : <span>{totalEmailRequest}</span>
        </div>
      </div>

      {/* Filters */}
      <div className="filters">
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Name."
            value={searchTerm}
            onChange={handleSearch}
            onKeyPress={(e) => {
              if (e.charCode === 32 && e.target.selectionStart === 0) {
                e.preventDefault(); // Prevent adding space at the beginning
              }
            }}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>

        <div className="search">
          <select
            id="status"
            required
            className="form-select"
            name="status"
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
              setSearchTerm("");
            }}
          >
            {/* <option value="" disabled>
              Select Status
            </option> */}
            <option value="pending">Pending</option>
            <option value="Approved">Approved</option>
            <option value="Rejected">Rejected</option>
          </select>
        </div>

        {/* Actions */}
        <div className="actions ms-2">
          <button
            className={
              "export theme-button" +
              (totalEmailRequest === 0 || filteredItems?.length === 0
                ? " disabled"
                : "")
            }
            onClick={exportToExcel}
            disabled={totalEmailRequest === 0 || filteredItems?.length === 0}
          >
            Export to Excel
          </button>
        </div>
      </div>
      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      {/* Data Table */}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom" id="table-approval-email-request">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Name</th>
                  <th>Purposed Email</th>
                  <th>Personal Email</th>
                  <th>Phone Number</th>
                  <th>Request By</th>
                  <th>Requested Date</th>
                  {selectedStatus === "Approved" && (
                    <>
                      <th>Caelius Email</th>
                      <th>Created By</th>
                      <th>Created Date</th>
                    </>
                  )}
                  <th>Comment</th>
                  <th>Status</th>
                  {selectedStatus !== "Approved" && <th>Action</th>}
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems &&
                  filteredItems?.map((item, index) => (
                    <tr key={index}>
                      <td data-title="Serialno">{index + 1}</td>
                      <td data-title="firstName">{item.firstName}</td>
                      <td data-title="purposedEmail">{item.purposedEmail}</td>
                      <td data-title="personalEmail">{item.personalEmail}</td>
                      <td data-title="phoneNumber">{item.phoneNumber}</td>
                      <td data-title="requestByName">{item.requestByName}</td>
                      <td data-title="requestDateTime">
                        {moment(item.requestDateTime).format("MM/DD/YYYY")}
                      </td>
                      {selectedStatus === "Approved" && (
                        <>
                          <td data-title="ccEmail">{item.ccEmail}</td>

                          <td data-title="createdByName">
                            {item.createdByName}
                          </td>
                          <td data-title="createdDateTime">
                            {moment(item.createdDateTime).format("MM/DD/YYYY")}
                          </td>
                        </>
                      )}
                      <td data-title="comment">{item.comment}</td>
                      <td data-title="status">
                        <small
                          className={`text-capitalize badge bg-${
                            item.status === "pending"
                              ? "warning"
                              : item.status === "Rejected"
                              ? "danger"
                              : "success"
                          }`}
                        >
                          {item.status}
                        </small>
                      </td>
                      {selectedStatus !== "Approved" && (
                        <td data-title="action">
                          {item.status === "pending" ? (
                            <a onClick={() => handleSelectEmailRequest(item)}>
                              View
                            </a>
                          ) : (
                            <p className="ms-2">-</p>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div>
                {/* Dropdown for selecting items per page */}
                <label htmlFor="itemsPerPage">Items per page </label>
                <select
                  id="itemsPerPage"
                  onChange={handleLimitChange}
                  value={limit}
                  className="border ms-3 w-25  p-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                </select>
              </div>
            </div>
            <div className="col-md-9">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}
      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center mt-5">
          <h4>No Record Available</h4>
        </div>
      )}
      <a
        data-bs-toggle="modal"
        data-bs-target="#staticBackdropEmailView"
        className="open-modal-email visually-hidden"
      >
        click
      </a>
      <div
        className="modal fade"
        id="staticBackdropEmailView"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <form onSubmit={handleSubmit} autocomplete="off">
              <div className="modal-header">
                <h5
                  className="modal-title text-center flex"
                  id="staticBackdropLabel"
                >
                  Approve Email Request Action
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleReset}
                ></button>
              </div>
              <div className="modal-body">
                {Object.keys(emailRequestSelected).length > 0 && (
                  <div className="table-responsive">
                    <table className="resume custom">
                      {/* Table Headings */}
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Name</th>
                          {/* <th>Alias Name</th> */}
                          <th>Purposed Email</th>
                          <th>Personal Email</th>
                          <th>Phone Number</th>
                          <th>Requested By</th>
                          <th>Requested Date</th>
                          <th>Status</th>
                          <th>Comment</th>
                        </tr>
                      </thead>

                      {/* Table Body */}
                      <tbody>
                        {emailRequestSelected && (
                          <tr>
                            <td data-title="id">{emailRequestSelected.id}</td>
                            <td data-title="firstName">
                              {emailRequestSelected.firstName}
                            </td>
                            {/* <td data-title="aliasName">
                              {emailRequestSelected.aliasName
                                ? emailRequestSelected.aliasName
                                : "-"}
                            </td> */}
                            <td data-title="purposedEmail">
                              {emailRequestSelected.purposedEmail}
                            </td>
                            <td data-title="personalEmail">
                              {emailRequestSelected.personalEmail}
                            </td>
                            <td data-title="phoneNumber">
                              {emailRequestSelected.phoneNumber}
                            </td>
                            <td data-title="requestByName">
                              {emailRequestSelected.requestByName}
                            </td>
                            <td data-title="requestDateTime">
                              {moment(
                                emailRequestSelected.requestDateTime
                              ).format("MM/DD/YYYY")}
                            </td>

                            <td data-title="status">
                              <small
                                className={`text-capitalize badge bg-${
                                  emailRequestSelected.status === "pending"
                                    ? "warning"
                                    : "success"
                                }`}
                              >
                                {emailRequestSelected.status}
                              </small>
                            </td>
                            <td data-title="comment">
                              {emailRequestSelected.comment}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                <div className="row mt-1">
                  <div className="col-md-2"></div>
                  <div className="col-md-4 ">
                    <label htmlFor="status" className="has-asterisk">
                      Status
                    </label>
                  </div>
                  <div className="col-md-4">
                    <div className="input-group">
                      <select
                        id="status"
                        required
                        name="status"
                        onChange={handleInputChange}
                        value={formData.status}
                        className="border"
                      >
                        <option value="">Select Status</option>
                        <option value="Approved">Approved</option>
                        <option value="Rejected">Rejected</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2"></div>
                </div>
                {formData.status === "Approved" && (
                  <>
                    <div className="row mt-3">
                      <div className="col-md-2"></div>
                      <div className="col-md-4">
                        <label htmlFor="ccEmail" className="has-asterisk">
                          Caelius Email
                        </label>
                      </div>
                      <div className="col-md-4 ">
                        <input
                          type="email"
                          name="ccEmail"
                          value={formData.ccEmail}
                          onChange={handleInputChange}
                          placeholder="Enter company email"
                          className="border"
                          autocomplete="off"
                          maxLength={100}
                          required
                        />
                        {errors.ccEmail && (
                          <div className="error text-danger">
                            {errors.ccEmail}
                          </div>
                        )}
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-2"></div>
                      <div className="col-md-4">
                        <label htmlFor="password" className="has-asterisk">
                          Password
                        </label>
                      </div>
                      <div className="col-md-4 ">
                        <input
                          type="password"
                          name="password"
                          className="border"
                          value={formData.password}
                          onChange={handleInputChange}
                          placeholder="Enter Password"
                          autocomplete="off"
                          required
                        />
                        {errors.password && (
                          <small className="text-danger">
                            {errors.password}
                          </small>
                        )}
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                  </>
                )}

                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label
                      htmlFor="comment"
                      className={`${
                        formData.status !== "Approved" && "has-asterisk"
                      }`}
                    >
                      Comment
                    </label>
                  </div>
                  <div className="col-md-4 ">
                    <textarea
                      rows={3}
                      name="comment"
                      className="border"
                      value={formData.comment}
                      onChange={handleInputChange}
                      maxLength={160}
                      required={formData.status !== "Approved"}
                      onKeyPress={(e) => {
                        // Prevent adding space at the beginning
                        if (
                          e.charCode === 32 &&
                          e.target.selectionStart === 0
                        ) {
                          e.preventDefault();
                        }

                        // Regular expression to allow only letters, numbers, and spaces
                        const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                        // Check if the character is allowed
                        const char = String.fromCharCode(e.charCode);
                        if (!allowedCharsRegex.test(char)) {
                          e.preventDefault(); // Prevent non-alphanumeric character input
                        }
                      }}
                    ></textarea>
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>

              <div className="justify-content-center modal-footer">
                <button
                  type="submit"
                  className={
                    isButtonLoading ? "theme-button disabled " : "theme-button "
                  }
                >
                  {isButtonLoading ? (
                    <ThreeDots
                      height="20"
                      width="53"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  type="button"
                  className="theme-button bg-grey mx-3 w-35 cancel-modal"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default EmailCreationRequestApproval;

const schema = Yup.object().shape({
  ccEmail: Yup.string()
    .email("Invalid email address")
    .required("Caelius Email is required")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email address"
    ),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters long"),
});
